import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { getRowAndColFromId, isObject } from '../../../components/sheet/helpers';
import Sheet from '../../../components/sheet/sheet';
import { getView2Data, getYears, sendScore } from '../../../utils';
import { incomeLevel, levelOfDevelopment, regions } from './constants';
import styles from './view3.module.scss';
import { setView3Table } from '../../../redux/actions/cms/view3';
import { useParams } from 'react-router-dom';
import { setView2Table } from '../../../redux/actions/cms/view2';
import { Box, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import _ from 'lodash';

export default function View2() {
  const history = useHistory();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { year } = useParams();
  const [years, setYears] = useState([]);
  const [data, setData] = useState([]);
  const [searchYear, setSearchYear] = useState(year || '');
  const [inputValue, setInputValue] = useState(searchYear);
  const cellTypes = {
    2: { type: 'select', options: regions },
    3: { type: 'select', options: levelOfDevelopment },
    4: { type: 'select', options: incomeLevel },
  };

  const actionDefs = {
    handleClick: (cell) => {
      const [rowIndex] = getRowAndColFromId(cell.id);
      const country = data[rowIndex][0];
      history.push(`/cms/view-3/${country}`);
    },
  };

  useEffect(() => {
    const getYearData = async () => {
      const years = await getYears('benchmark');
      setYears(years);
      setSearchYear(state || years[0]);
    };
    getYearData();
  }, []);

  useEffect(() => {
    const setInitialData = async () => {
      if (searchYear) {
        const responseData = await getInitialData(searchYear);
        dispatch(setView3Table(responseData));
        setData(responseData);
      } else {
        const response = await Promise.all(
          years?.map(async (year, index) => {
            const data = await getInitialData(year);
            index === 0 ? data : data.shift();
            return data;
          })
        );
        const res = response.reduce((prev, next) => [...prev, ...next], []);
        dispatch(setView2Table(res));
        setData(res);
      }
    };
    setInitialData();
  }, [searchYear, years]);

  const getInitialData = async (searchYear) => {
    const { data } = await getView2Data(`benchmark/${searchYear}`);
    const header = {};
    data?.length &&
      Object.keys(data[0]).forEach((elem) => {
        header[elem] = elem;
      });
    return [header, ...data];
  };

  const handleChangeYear = (_, v) => {
    setSearchYear(v || '');
    setInputValue(v ? v.toString() : '');
  };

  const handleDataChange = (data) => setData(data);

  const handleOnBlur = async (e, cell, j, row) => {
    const newCell = isObject(cell)
      ? {
          ...cell,
          value: {
            ...cell.value,
            score: e.target.value,
            overridden: true,
          },
        }
      : { ...cell, value: e.target.value };
    const requestRow = {
      ...row,
      [j]: newCell,
    };
    Object.keys(requestRow).forEach((key) => {
      requestRow[key] = requestRow[key].value;
    });
    const newData = _.cloneDeep(data);
    const index = newData.findIndex((item) => item.key === requestRow.key);
    const responseData = await sendScore(`benchmark`, requestRow);
    newData[index] = responseData;
    setData(responseData);
  };

  return (
    <div style={{ padding: '20px' }}>
      <div className={styles.filters}>
        <Autocomplete
          disablePortal
          options={years}
          getOptionLabel={(option) => option.toString()}
          style={{ width: '300px' }}
          inputValue={inputValue || ''}
          onInputChange={(e) => {
            e && setInputValue(e.target.value) && setSearchYear(e.target.value);
          }}
          value={searchYear}
          getOptionSelected={(option) => option}
          onChange={handleChangeYear}
          renderInput={(params) => <TextField variant="outlined" {...params} style={{ width: '100%' }} label="Country" />}
          renderOption={(props) => (
            <Box component="span" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {props}
            </Box>
          )}
        />
      </div>
      <div className={styles.sheetCont}>
        <Sheet actionDefs={actionDefs} data={data} cellTypes={cellTypes} isScore setter={handleDataChange} handleOnBlur={handleOnBlur} />
      </div>
    </div>
  );
}

import { SAVE_VIEW3_TABLE, SET_VIEW3_TABLE } from '../../types';

// TODO: find out about "timeout" while typing.
// let timeoutId = null;

const setView3Table = (data) => async (dispatch) => {
  dispatch({
    type: SET_VIEW3_TABLE,
    payload: { loading: true },
  });
  const payload = {
    table: data,
    loading: false,
  };
  dispatch({
    type: SET_VIEW3_TABLE,
    payload,
  });
};

const saveView3Table = () => async (dispatch) => {
  dispatch({
    type: SAVE_VIEW3_TABLE,
    payload: { loading: true },
  });
  dispatch({
    type: SAVE_VIEW3_TABLE,
  });
};

export { setView3Table, saveView3Table };

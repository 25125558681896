import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en';

i18n.use(initReactI18next).init({
  resources: { en },
  fallbackLng: 'en',
  debug: false,
  ns: ['translations', 'lab', 'benchmark'],
  defaultNS: 'translations',
  keySeparator: '.',
  interpolation: {
    escapeValue: false,
    formatSeparator: ',',
  },
  react: {
    useSuspense: false,
  },
});

i18n.languages = ['en-US'];

export default i18n;

export const routes = (country) => [
  {
    path: '/cms',
    breadcrumb: 'CMS home',
  },
  {
    path: '/cms/benchmark/view-1',
    breadcrumb: 'Countries',
  },
  {
    path: '/cms/tracker/view-1',
    breadcrumb: 'Countries',
  },
  {
    path: '/cms/view-2',
    breadcrumb: 'Tracker',
  },
  {
    path: `/cms/view-2/${country}`,
    breadcrumb: country,
  },
  {
    path: '/cms/view-3',
    breadcrumb: 'Benchmark',
  },
  {
    path: `/cms/view-3/${country}`,
    breadcrumb: country,
  },
  {
    path: `/cms/view-2/raw-data`,
    breadcrumb: 'Raw data',
  },
  {
    path: `/cms/view-3/raw-data`,
    breadcrumb: 'Raw data',
  },
];

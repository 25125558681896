import { publicApi } from '../api/config';

const getBase = () => (window.location.href.toLowerCase().includes('tracker') ? '/tracker' : '/benchmark');

export async function getCountryEntries(baseRoute) {
  const base = baseRoute ?? getBase();
  const { data: entries } = await publicApi.get(`${base}/entries`);
  return entries;
}

export async function getEntries(year, baseRoute) {
  const base = baseRoute ?? getBase();
  const { data: entries } = await publicApi.get(`${base}/entries?year=${year}`);
  return entries;
}

export async function getYears(baseRoute) {
  const base = baseRoute ?? getBase();
  const { data: years } = await publicApi.get(`${base}/years`);
  return years.reverse();
}

export async function publishYear(path) {
  const data = await publicApi.put(`cms/campaign/${path}`);
  return data;
}

export async function getCountryStats(country, year, baseRoute) {
  const base = baseRoute ?? getBase();
  const options = `${country ? `country=${country}` : ''}${year ? `&year=${year}` : ''}&brief=false`;
  const { data: countryInfo } = await publicApi.get(`${base}/entries?${options}`);
  return countryInfo;
}

export async function getCountryNameAndFlags(baseRoute) {
  const base = baseRoute ?? '/tracker';
  const { data: countries } = await publicApi.get(`${base}/countries`);
  return countries;
}

export async function getLatestYearAvailableIndicators(baseRoute, isoCode) {
  const base = baseRoute ?? '/tracker';
  const { data: activeMobileInd } = await publicApi.get(`${base}/countries?codes=["i271mw"]&isocode=${isoCode}`);
  const { data: fixedInd } = await publicApi.get(`${base}/countries?codes=["i4213tfbb"]&isocode=${isoCode}`);
  const { data: inetUsersInd } = await publicApi.get(`${base}/countries?codes=["i99H"]&isocode=${isoCode}`);

  return { activeMobileInd, fixedInd, inetUsersInd };
}

export async function getClasses() {
  const { data } = await publicApi.get(`/classes`);
  return data;
}

export async function getMetricsData(formulaKey, parameters = {}) {
  const data = await publicApi.get(`data/${formulaKey}`, { params: { parameters } });
  return data;
}

export async function getCountriesStatsForMultipleYears(countries, years, baseRoute) {
  const base = baseRoute ?? getBase();
  const result = await publicApi.get(`${base}/entries?countries=[${countries}]&years=[${years}]&brief=false`);
  if (result.data) {
    return result.data;
  }
  return null;
}

export async function getTrackerData(path, parameters = {}) {
  const data = await publicApi.get(`tracker/${path}`, { params: { parameters } });
  return data;
}

export async function getBenchmarkData(path, parameters = {}) {
  const data = await publicApi.get(`benchmark/${path}`, { params: { parameters } });
  return data;
}

export async function getView2Data(path) {
  const data = await publicApi.get(`cms/campaign/${path}`);
  return data;
}

export async function getNewYear(path) {
  const data = await publicApi.post(`cms/campaign/${path}?duplicate=true`);
  return data;
}

export async function getView2DataByCountry(path) {
  const data = await publicApi.get(`cms/${path}`);
  return data;
}

export async function updateViews(path, body) {
  const data = await publicApi.put(`cms/${path}`, { ...body });
  return data;
}

export async function sendScore(path, body) {
  const data = await publicApi.post(`cms/${path}/score`, { ...body });
  return data;
}

import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './secondHeader.module.scss';

export default function SecondHeader() {
  const { REACT_APP_ACCELERATOR, REACT_APP_REG_TRACKER, REACT_APP_BENCHMARK, REACT_APP_IMPACT_LAB, REACT_APP_UNIFIED_FRAMEWORK } = process.env;

  const { t } = useTranslation(['lab']);
  return (
    <div className={styles.header}>
      <a href={REACT_APP_ACCELERATOR} target="_blank" rel="noreferrer">
        {t('G5Accelerator')}
      </a>
      <a href={REACT_APP_REG_TRACKER} className={clsx({ [styles.active]: window.location.pathname.includes('tracker') })} target="_blank" rel="noreferrer">
        {t('ICTRegulatoryTracker')}
      </a>
      <a href={REACT_APP_BENCHMARK} className={clsx({ [styles.active]: window.location.pathname.includes('benchmark') })} target="_blank" rel="noreferrer">
        {t('G5Benchmark')}
      </a>
      <a href={REACT_APP_UNIFIED_FRAMEWORK} className={clsx({ [styles.active]: window.location.pathname.includes('unified-framework') })} target="_blank" rel="noreferrer">
        {t('unifiedFramework')}
      </a>
      {/* <a href={REACT_APP_IMPACT_LAB} className={clsx({ [styles.active]: window.location.pathname.includes('lab') })} target="_blank" rel="noreferrer">
        {t('ICTPolicyImpactLab')}
      </a> */}
    </div>
  );
}

import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import styles from './view2.module.scss';
import { getYears, publishYear } from '../../../utils';

const PublishButton = ({ onClick }) => (
  <Button className={styles.btn} variant="contained" color="primary" size="large" onClick={onClick}>
    Publish
  </Button>
);

export default function Publish() {
  const [data, setData] = useState();

  const handlePublish = async (year) => {
    const response = await publishYear(`tracker/${year}`);
  };

  useEffect(() => {
    (async () => {
      const years = await getYears('tracker');
      setData(years.map((year) => ({ year, btn: <PublishButton onClick={() => handlePublish(year)} /> })));
    })();
  }, []);

  return (
    <div className={styles.center}>
      <div className={styles.container}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 350 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Year</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((row) => (
                <TableRow key={row.year} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {row.year}
                  </TableCell>
                  <TableCell>{row.btn}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

import { useEffect, useRef } from 'react';

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export const getRowAndColFromId = (id) => id.split('_').map((item) => +item);

export const cache = {
  getItem(name) {
    return JSON.parse(window.localStorage.getItem(name)) || [];
  },
  removeItem(name) {
    return window.localStorage.removeItem(name);
  },
  setItem(name, data) {
    window.localStorage.setItem(name, JSON.stringify(data));
  },
};

export const createRows = (n, m, startIndex = 0, data, isAddition = false, actionDefs) => {
  const initialRows = [{}];
  const colSize = data?.length || n;
  const rowSize = data.length && m < Object.keys(data[0]).length ? Object.keys(data[0]).length : m;
  for (let i = 0; i < colSize; i++) {
    if (!Array.isArray(initialRows[i])) {
      initialRows[i] = {};
    }

    if (data.length) {
      Object.entries(data?.[i]).forEach(([key, value], j) => {
        initialRows[i][key] = {
          value,
          id: `${i + startIndex}_${j}`,
          type: startIndex <= 0 && i === 0 ? 'column' : 'cell',
          cellType: { type: 'input' },
          // cellType: getCellType(i, j),
        };
      });
    } else {
      for (let j = 0; j < rowSize; j++) {
        initialRows[i][j] = {
          id: `${i + startIndex}_${j}`,
          value: data[i]?.[j] ?? '',
          type: startIndex <= 0 && i === 0 ? 'column' : 'cell',
          cellType: { type: 'input' },
          // cellType: getCellType(i, j),
        };
      }
    }
  }

  if (actionDefs) {
    initialRows.forEach((item, i) => {
      return i || isAddition
        ? {
            ...item,
            type: 'cell',
            cellType: { type: 'action' },
            actionDefs,
          }
        : {
            ...item,
            value: 'Action',
            type: 'column',
            cellType: { type: 'input' },
            // cellType: getCellType(i, item.length),
          };
    });
  }

  return initialRows;
};

export const isObject = (elem) => typeof elem.value === 'object' && elem.value !== null;

export const headersSubView2 = ['Year', 'code', 'region', 'level Of Development', 'income level', 'code', 'region', 'level Of Development', 'income level'];
export const headersRawData = ['Year', 'text 2', 'text 3', 'text 3', 'text 4', 'text 5', 'text 6', 'text 7', 'text 8'];

export const regions = ['Africa', 'Arab States', 'CIS', 'East Asia  and Pacific', 'Europe', 'Latin America and Caribbean', 'North America', 'South Asia'];
export const levelOfDevelopment = ['Developed', 'Developing', 'LDCs', 'LLDCs', 'SIDS'];
export const incomeLevel = ['Low', 'Low middle', 'Upper middle', 'High'];
export const headers = [
  'country',
  'code',
  'region',
  'level Of Development',
  'income level',
  'region',
  'level Of Development',
  'income level',
  'region',
  'level Of Development',
  'income level',
  'region',
  'level Of Development',
  'income level',
];

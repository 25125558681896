import { SAVE_VIEW2_TABLE, SET_VIEW2_TABLE } from '../../types';

const initialState = {
  table: [],
  loading: false,
  savedTable: [],
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_VIEW2_TABLE:
      return { ...state, ...action.payload };
    case SAVE_VIEW2_TABLE:
      return { ...state, savedTable: [...state.table] };
    default:
      return { ...state };
  }
};

import { useEffect, useRef, useState } from 'react';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { NavLink, Route, Switch, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Box, Breadcrumbs as BreadcrumbsUI } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import Home from '../pages/cms/home';
import Footer from '../components/FooterLab/Footer';
import AppContext from '../contexts/AppContext';
import styles from '../Lab.module.scss';
import SecondHeader from '../components/HeaderLab/secondHeader';
import NotFound from '../components/notFound/NotFoundInner';
import View1 from '../pages/cms/view1';
import View2 from '../pages/cms/view2';
import View3 from '../pages/cms/view3';
import SubView2Country from '../pages/cms/view2/subView2Country';
import SubView3Country from '../pages/cms/view3/subView3Country';
import SubView2Publish from '../pages/cms/view2/publish';
import SubView3Publish from '../pages/cms/view3/publish';
import { routes } from '../pages/cms/components/breadcrumbs/routes';
import RawDataView2 from '../pages/cms/view2/rawData';
import RawDataView3 from '../pages/cms/view3/rawData';
import Loading from '../components/Loading';
import ErrorModal from '../components/ErrorModal';

const { REACT_APP_ACCELERATOR } = process.env;

const topMenuPaths = [REACT_APP_ACCELERATOR, '/lab/telecom', '/lab/mobile', '/lab/study/summary', '/lab'];

const theme = createTheme({
  typography: { fontFamily: 'Avenir, Arial, sans-serif' },
  palette: { primary: { main: '#009CD5' }, secondary: { main: '#009CD5' } },
});

const App = () => {
  const { pathname } = useLocation();
  const appRef = useRef();
  const [appNode, setAppNode] = useState();
  const [menuItemClicked, setMenuItemClicked] = useState(false);
  const { t } = useTranslation(['cms']);

  const menuItems = [
    { id: 1, name: t('view-1'), path: '/cms/tracker/view-1', component: View1 },
    { id: 1, name: t('view-1'), path: '/cms/benchmark/view-1', component: View1 },
    { id: 2, name: t('view-2'), path: '/cms/view-2', component: View2 },
    { id: 3, name: t('view-3'), path: '/cms/view-3', component: View3 },
  ];

  const split = window.location.pathname.split('/');
  const country = split[split.length - 1];
  const breadcrumbs = useBreadcrumbs(routes(country), { disableDefaults: true });

  useEffect(() => setAppNode(appRef.current), []);

  useEffect(() => {
    const isMenuItemChanged = appRef.current && topMenuPaths.includes(pathname);
    if (isMenuItemChanged || menuItemClicked) {
      appRef.current.scrollTo(0, 0);
      setMenuItemClicked(false);
    }
  }, [pathname, menuItemClicked]);

  return (
    <AppContext.Provider value={{ appNode }}>
      <Helmet>
        <title>ITU | ICT CMS</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <div className={styles.container}>
          <SecondHeader />
          {/* <Header
            homePage="/CMS"
            type="CMS"
            menuItems={menuItems}
            onMenuItemClicked={() => setMenuItemClicked(true)}
          /> */}
          <main className={styles.main} ref={appRef}>
            <Box mx={3} my={2} className={styles.wrapper}>
              <BreadcrumbsUI>
                {breadcrumbs.map(({ breadcrumb }) => (
                  <NavLink key={breadcrumb.key} to={breadcrumb.key} exact className={styles.breadCrumb} activeClassName={styles.activeBreadCrumb}>
                    {breadcrumb}
                  </NavLink>
                ))}
              </BreadcrumbsUI>
            </Box>
            <Switch>
              <Route exact component={Home} path="/cms" />
              {menuItems.map((item) => (
                <Route key={item.id} exact component={item.component} path={item.path} />
              ))}
              <Route component={SubView2Publish} exact path="/cms/view-2/publish" />
              <Route component={RawDataView2} exact path="/cms/view-2/raw-data" />
              <Route component={SubView3Publish} exact path="/cms/view-3/publish" />
              <Route component={RawDataView3} exact path="/cms/view-3/raw-data" />
              <Route component={SubView2Country} exact path="/cms/view-2/:country" />
              <Route component={SubView3Country} exact path="/cms/view-3/:country" />
              <Route path="*" component={NotFound} />
            </Switch>
            <Footer />
          </main>
          <Loading />
          <ErrorModal />
        </div>
      </ThemeProvider>
    </AppContext.Provider>
  );
};

export default App;

import React from 'react';
import { useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import classes from './Loading.module.scss';

const Loading = () => {
  const loading = useSelector((state) => state.loading);
  return (
    loading && (
      <div className={classes.backdrop}>
        <div className={classes.loading}>
          <ClipLoader color="inherit" size={50} />
        </div>
      </div>
    )
  );
};

export default React.memo(Loading);

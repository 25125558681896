import { combineReducers } from 'redux';

import loadingReducer from './reducers/loadingReducer';
import errorReducer from './reducers/errorReducer';
import view1 from './reducers/cms/view1';
import view2 from './reducers/cms/view2';
import view3 from './reducers/cms/view3';
import subView2 from './reducers/cms/subView2';
import subView3 from './reducers/cms/subView3';
import rawDataView2 from './reducers/cms/rawDataView2';
import rawDataView3 from './reducers/cms/rawDataView3';

const rootReducer = combineReducers({
  loading: loadingReducer,
  error: errorReducer,
  cmsView1: view1,
  cmsView2: view2,
  cmsSubView2: subView2,
  cmsView3: view3,
  cmsSubView3: subView3,
  cmsRawDataView2: rawDataView2,
  cmsRawDataView3: rawDataView3,
});

export default rootReducer;

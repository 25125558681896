import { SAVE_SUB_VIEW2_TABLE, SET_SUB_VIEW2_TABLE } from '../../types';

const setSubView2Table = (data) => async (dispatch) => {
  dispatch({
    type: SET_SUB_VIEW2_TABLE,
    payload: { loading: true },
  });
  const payload = {
    table: data,
    loading: false,
  };
  dispatch({
    type: SET_SUB_VIEW2_TABLE,
    payload,
  });
};

const saveSubView2Table = () => async (dispatch) => {
  dispatch({
    type: SAVE_SUB_VIEW2_TABLE,
    payload: { loading: true },
  });
  dispatch({
    type: SAVE_SUB_VIEW2_TABLE,
  });
};

export { setSubView2Table, saveSubView2Table };

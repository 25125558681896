// ESLINT-disable jsx-a11y/control-has-associated-label

import clsx from 'clsx';
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { ReactComponent as Globe } from '../../assets/icons/SVG/404.svg';
import styles from './notFound.module.scss';

function NotFound({ withLink }) {
  const { t } = useTranslation();
  return (
    <div className={styles.notFound}>
      <Globe />
      <div className={clsx(styles.text, styles.bold)}>404</div>
      <div className={clsx(styles.text, styles.pageGoneText)}>{t('not_found')}</div>
      {withLink && (
        <div className={clsx(styles.goto)}>
          <Trans
            i18nKey="notFound.gotoLink"
            defaults={t('notFound.gotoLink')}
            components={{
              link1: (
                <a aria-label="accelerator" aria-hidden="true" href="https://gen5.digital/">
                  {t('notFound.gotoLink')}
                </a>
              ),
            }}
          />
        </div>
      )}
    </div>
  );
}

export default NotFound;

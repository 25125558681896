// eslint-disable-next-line import/no-anonymous-default-export
// eslint-disable -max-len
export default {
  translations: {
    header: {
      title: 'ITU Simulation',
      G5Accelerator: 'G5 Accelerator',
      ICTRegulatoryTracker: 'ICT Regulatory Tracker',
      G5Benchmark: 'G5 Benchmark',
      ICTPolicyImpactLab: 'ICT Policy Impact Lab',
    },
    menu: {
      tracker: 'Tracker',
      dashboards: 'Dashboards',
      about: 'Methodology',
      g5console: 'G5 Console',
      countryCards: 'Country Cards',
      resources: 'Resources',
      metrics: 'Data',
      publications: 'Publications',
      accelerator: 'Accelerator',
      charts: 'Charts',
      concepts: 'Concepts',
      map: 'Map',
    },
    footer: {
      social: 'Social Media',
      copyright: 'ITU. All right reserved',
      reportText: 'Report misconduct',
      privacyText: 'Terms of use and privacy notice',
      contactText: 'Contact us',
      visit: 'visit',
      itu: 'ITU',
      sites: {
        facebook: 'Facebook',
        instagram: 'Instagram',
        twitter: 'Twitter',
        linkedin: 'LinkedIn',
        tiktok: 'Tiktok',
        youtube: 'Youtube',
        flickr: 'Flickr',
        spotify: 'Spotify',
        soundcloud: 'Soundloud',
        podcasts: 'Podcasts',
        spreaker: 'Spreaker',
      },
    },
    countryCards: {
      caption: `Note: The ICT Regulatory Tracker is based on self-reported information gathered via official ITU Surveys to Member States Administrations, 
      datasets compiled by international organizations as well as desktop research based on official government sources and direct outreach to national
      telecom/ICT regulatory authorities. Official data received from Member States Administrations has been verified to the extent reasonably feasible.`,
    },
    countryCardsBenchmark: {
      caption: `Note: The G5 Benchmark is based on self-reported information gathered via official ITU Surveys to Member States Administrations, 
      datasets compiled by international organizations as well as desktop research based on official government sources and direct outreach to national
      telecom/ICT regulatory authorities. Official data received from Member States Administrations has been verified to the extent reasonably feasible.`,
    },
    notFound: {
      gotoLink: 'Go to <link1>Accelerator</link1>',
    },
    about: {
      title: 'About tracker',
      chapter0: {
        title: 'Introduction',
      },
      chapter1: {
        title: 'What is the Tracker?',
        content: `The ICT Regulatory Tracker is an evidence-based tool to help decision-makers
                  and regulators make sense of the rapid evolution of ICT regulation.
                  The Tracker pinpoints the changes taking place in the ICT regulatory environment.
                  It facilitates benchmarking and the identification of trends in ICT legal and regulatory frameworks.
                  <p>The Tracker does not measure the quality, the level of implementation or the performance of regulatory frameworks in place,
                  but records their existence and features. It helps track progress and identify gaps in regulatory frameworks,
                  making the case for further regulatory reform towards achieving a vibrant and inclusive ICT sector.</p>`,
      },
      chapter2: {
        title: 'Scope',
        content: `The ICT Regulatory Tracker is composed metric based on a total of 50 indicators (11 composite,
                  see full list below) grouped into four clusters:
                  <br/>
                  <ul>
                  <li>Regulatory authority (focusing on the functioning of the separate regulator),</li>
                  <li>Regulatory mandates (who regulates what),</li>
                  <li>Regulatory regime (what regulation exists in major areas) and</li>
                  <li>Competition framework for the ICT sector (level of competition in the main market segments).</li>
                  </ul>
                  <br/>
                  The Tracker covers between 190 and 193 countries and economies over the period 2007 – 2022.`,
      },
      chapter3: {
        title: 'Structure and composition',
        content: `After coding the originally qualitative information, all indicators are given a score between 0 and 2. 
                  The benchmark for the scoring is what is considered the best possible scenario based on the internationally recognized
                  regulatory best practices that were adopted by the global community of regulators
                  at the annual <link1>ITU Global Symposiums for Regulators.</link1>`,
      },
      chapter4: {
        title: 'Data mechanics: coding & scores',
        content: `<image1/> <br/> The full set of indicators is showed in the table below. 
        <br/> 
        <br/> 
        ICT Regulatory Tracker indicators, per pillar.
        <br/> 
        <br/> 
        <image3/>
        <image4/>
        <image5/>
        `,
      },
      chapter5: {
        title: 'Sources and validation of data',
        content: `The ICT Regulatory Tracker is primarily based on self-reported information gathered via official ITU surveys to Member State administrations, datasets
        compiled by international organizations, as well as desktop research based on official government sources and direct outreach to national
        telecommunication and ICT regulatory authorities. Official data received from Member State administrations has been verified to the extent
        reasonably feasible and, when necessary, aligned with publicly available official government sources, in view of ensuring high level of coherence
        and robustness of the dataset. The research team has strived to ensure the accuracy of data to the greatest extent possible; nevertheless, in rare
        cases it may be incomplete or include irregular or odd values.`,
      },
      chapter6: {
        title: 'Disclaimers',
        content: `
        The ICT Regulatory Tracker is based on self-reported information gathered via official ITU Surveys to Member States 
        institutions, datasets compiled by international organizations as well as desktop research based on official government sources 
        and direct outreach to national telecom/ICT regulatory authorities. Official data received from Member States has been verified 
        to the extent reasonably feasible. The research team endeavours to ensure the accuracy of data to the greatest extent possible; 
        nevertheless, we do not warrant its completeness, nor we exclude the possibility of irregular or odd values in rare cases.
        <br> 
        <br>
        The designations employed and the presentation of material in this information product do not imply the expression of any opinion 
        whatsoever on the part of the International Telecommunication Union (ITU) concerning the legal or development status of any country, 
        territory, city or area or of its authorities, or concerning the delimitation of its frontiers or boundaries.
        <br>
        <br>
        All reasonable precautions have been taken by ITU to verify the information contained in this website. However, the published material 
        is being distributed without warranty of any kind, either expressed or implied. The responsibility for the interpretation and use of 
        the material lies with the reader. The opinions, findings and conclusions expressed in this publication do not necessarily reflect 
        the views of ITU or its membership.`,
      },
      chapter7: {
        title: 'Generations of regulation',
        content: `To help analyze the evolution of ICT regulation worldwide, identify progress areas as well as gaps and measure those,
        the countries included in the Tracker are split into score thresholds that relate to generations of regulation, for any given year.
        <br/>
        <br/>
        <image1/>
        <br/>
        <br/>
        ICT Regulatory Tracker Score breakdown
        <ul>
        <li><b>G1</b>: ≥ 0 < 40</li>
        <li><b>G2</b>: ≥ 40 < 70</li>
        <li><b>G3</b>: ≥ 70 < 85</li>
        <li><b>G4</b>: ≥ 85 ≤ 100</li>
        </ul>
        Rationale
        <ul>
        <li><b>G1</b>: Regulated public monopolies– command and control approach</li>
        <li><b>G2</b>: Basic reform – partial liberalization and privatization across the layers</li>
        <li><b>G3</b>: Enabling investment, innovation and access – dual focus on stimulating
        competition in service and content delivery, and consumer protection</li>
        <li><b>G4</b>: Integrated regulation – led by economic and social policy goals</li>
        </ul>
        <br/>
        Using the concept of generations of regulation, the Tracker can be used to showcase progress
        within the same country over time, compare between countries and regions or track the ICT
        regulatory trends in specific areas at the national, regional and global level.
        <br/>
        <br/>
        The <link0>1st edition of the Global ICT Regulatory Outlook (2017)</link0>
        provides in-depth analysis on the generations of regulation and their evolution
        <br/>
        <br/>
        The <link1>2nd edition of the Global ICT Regulatory Outlook (2019)</link1> builds on
        its predecessor in featuring unique evidence from around the world charting the evolution of
        policy and regulation in ICTs and, by extension, in the digital economy.
        <br/>
        <br/>
        The <link2>3rd edition of the Global
        ICT Regulatory Outlook (2020)</link2> features unique, focused research and offer both evidence and
        practical advice to support regulators embarked on their journey to fifth generation collaborative regulation.
        <br/>
        <br/>
        The 4th edition in the series, the <link3>Global Digital Regulatory Outlook 2023 - Policy and
        regulation to spur digital transformation</link3>, benchmarks regulatory progress across 193
        countries worldwide, building on the successful track record of the first three editions.
        This new analysis is the go-to reference for regulators and policy-makers seeking to
        understand a fast-moving landscape - and shape regulatory change that will benefit all in
        the quest for digital transformation.
        `,
      },
      chapter8: {
        title: 'Detailed methodology',
        content: `The matrix with the detailed methodology of the ICT Regulatory Tracker can be downloaded here. 
          It provides detailed information on the choice, composition and scoring of each indicator <link0>here</link0>.`,
      },
      chapter9: {
        title: 'Copyright notice',
        content: `<i>ICT Regulatory Tracker <link0>gen5.digital</link0>, © 2007-2022, ITU, some rights reserved.</i>
        <br/>
        <link1><image0/></link1>
        <br/>
        The data on this platform (including the datasets and data visualization) is licensed to the public through a 
        Creative Commons Attribution-Non-Commercial-ShareAlike 3.0 IGO license. Under the <link1>terms of this licence</link1>, you may copy, 
        redistribute, and adapt the Data for non-commercial purposes, provided the work is appropriately cited.
        <br/>
        <br/>
        Suggested citation: International Telecommunication Union, ICT Regulatory Tracker, <link2>gen5.digital</link2>. 
        It is recommended to indicate the access date, since the data is updated on a regular basis. In any use of the data, 
        there should be no suggestion that ITU endorse any specific organization, products, or services. 
        The unauthorized use of the ITU name or logo is not permitted.
        `,
      },
      chapter10: {
        title: 'Feedback & contact',
        content: `
        If you are an ITU Member State Administration and you wish to provide recent or historic data
        for your country’s ICT regulation, please write to us. <mail0>treg@itu.int</mail0>
        <br/>
        If you would like to know more about the tracker or have queries or suggestions, please get back to us. <mail1>treg@itu.int</mail1>
        `,
      },
      chapter11: {
        title: 'Process for updating country profiles of ITU Administrations',
        content: `The ICT Regulatory Tracker is updated every two years based on submissions by ITU Member State
        administrations and national ICT regulators to the ITU World Telecommunication/ICT Regulatory
        Survey. Country submissions go through a standard verification and validation process carried out by
        the ITU Secretariat before they are published on the <link0>ITU Datahub</link0>, the ITU data dissemination
        platform.
        <br/>
        
        <br/>
        In order to ensure that the ICT Reg ulatory Tracker is as complete as possible, the ITU Secretariat
        may, in some cases, carry out additional research based exclusively on official government sources
        to complete data gaps and improve the completeness, accuracy and overall robustness of the
        dataset.
        <br/>  
        <br/>  
        A review of their country profiles can be done at any time following the steps below.
        <br/>
        <ul>
          <li>
            ITU Member State Administrations can submit a request for review <link1>by email</link1> indicating the
            reason for the request and any new elements to be considered.
          </li>
          <li>
            ITU Secretariat will carry out the review of the new elements in the three months following
            the submission of the request. The Secretariat reserves the right to request additional
            references, documentation or explanation on specific points in order to be in a position to
            complete the review. A final report is then produced, which may result in a change of
            individual indicators or scores (either positive or negative), or in the confirmation of the data
            on the country profile (no change).
          </li>
          <li>
            The report is submitted to the country for information and is considered final, until the next
            regular update of the ICT Regulatory Tracker.
          </li>
          <li>
            If changes have been made to individual indicators and scores, these will be reflected online
            in the month following the review completion at the latest.
          </li>
        </ul>
        For the purposes of the review, new elements should meet the following requirements:
        <br/>
        <ul>
        <li>
          Comply with the definition of the respective indicators and targets, as per the established
          methodology;
        </li>
        <li>
          Refer to legal instruments, policies, institutional arrangements, initiatives or else already in
          force at the date of the latest dataset available (e.g., no draft regulations or pending
          approvals);
        </li>
        <li>
          Accessible online or alternatively, submitted to the Secretariat as searchable PDF or
          Microsoft Word documents in their entirety (no scanned or incomplete documents). In case
          such documents are not intended for public distribution, this should be clearly marked, and
          they will only be consulted by the Secretariat for the purposes of the review and not
          disclosed to third parties.
        </li>
      </ul>
        `,
      },
    },
    modal: {
      error: {
        title: 'Something went wrong. Please try again later',
      },
      close: 'close',
    },
    methodology: 'Note on Methodology',
    back_to_tracker: 'Back',
    not_found: 'This page is gone',
  },

  lab: {
    logo: 'ITU Logo',
    globalErrorMessage: 'Ooops, Something went wrong...',
    regulatory: 'Regulatory',
    mobile: 'Mobile Sector',
    telecom: 'Telecom Sector',
    study: 'Study',
    result: 'Result  Impact on Mobile CAPEX (growth rate)',
    selectAll: 'Select all',
    change: 'Change',
    ICTRegTrack: 'IMPACT OF POLICY VARIABLES ON CAPEX',
    mobileSelect: 'Select policies to calculate their impact',
    impactOnCapex: 'Impact on CAPEX',
    download: 'Download',
    accelerator: 'Accelerator',
    G5Accelerator: 'G5 Accelerator',
    ICTRegulatoryTracker: 'ICT Regulatory Tracker',
    G5Benchmark: 'G5 Benchmark',
    ICTPolicyImpactLab: 'ICT Policy Impact Lab',
    unifiedFramework: 'Unified Framework',

    header: {
      title: 'ITU Simulation',
    },
    footer: {
      social: 'Social Media',
      copyright: 'ITU. All right reserved',
      reportText: 'Report misconduct',
      privacyText: 'Terms of use and privacy notice',
      contactText: 'Contact us',
      visit: 'visit',
      itu: 'ITU',
      sites: {
        facebook: 'Facebook',
        instagram: 'Instagram',
        twitter: 'Twitter',
        linkedin: 'LinkedIn',
        tiktok: 'Tiktok',
        youtube: 'Youtube',
        flickr: 'Flickr',
        spotify: 'Spotify',
        soundcloud: 'Soundloud',
        podcasts: 'Podcasts',
        spreaker: 'Spreaker',
      },
    },
    homepage: {
      welcome: {
        title: 'Explore the impact of policies and regulations on ICT investment',
        firstText: `Pinpoint the impact of regulatory policies and institutional frameworks on the ICT sector performance
          and its contribution to national economies. Explore the impact of reforms on investment.
          Learn from the experience of 145 countries between 2008 and 2019.`,
        secondText: `This simulation lab is based on empirical evidence and developed to
          support ICT regulators and policy makers in reigniting digital markets and economies.`,
        getStarted: {
          title: 'Get Started',
          par1:
            'Navigate through the overall telecommunications sector or the mobile segment' + 'to explore the impact of targeted policies affecting markets based on historic data and global trends.',
        },
      },
      sectors: {
        first: {
          title: 'Overall Sector',
          text: 'Access the lab to estimate the impact of specific policies on the overall ' + 'telecommunications (fixed and mobile) investment (fixed and mobile segments)',
        },
        second: {
          title: 'Mobile Sector',
          text: 'Access the lab to estimate the impact of specific policies on investment,' + 'coverage and prices, adoption of mobile services and the net effect on national GDP per capita',
        },
        button: 'Go to the Tool',
      },
      readStudy: {
        title: 'We recommend you to read the study before using the simulation tool',
        text: {
          part1: 'This lab relies on the econometric modelling findings contained in the ITU study ',
          part2: 'The impact of policies, regulation, and institutions on ICT sector performance',
          part3: 'showing that ICT regulation has had a measurable impact on the growth of global ICT markets over the past decade. ',
          part4:
            'Read the study to familiarize yourself with the methodology' + 'used in our empirical models and the full detailed results of the' + 'impact of specific policies on market outcomes.',
        },
        button: 'Read the study',
      },
    },
    mobileSector: {
      title: 'Mobile Sector',
      subtitle: 'What do you want to explore?',
      subtitle2: 'Select a policy variable, % change and pick an impact area',
      growthRate: 'Growth rate',
      cumulativeImpact: 'Cumulative impact',
      activeCharts: 'Select an impact area to measure the impact of the policy reform/s selected above:',
      chartsTitles: {
        CAPEX: 'Impact of investment in the mobile sector after a % change in the selected policy variable/s on Mobile CAPEX',
        coverage: 'Impact of investment in the mobile sector after a % change in the selected policy variable/s on Coverage',
        prices: 'Impact of investment in the mobile sector after a % change in the selected policy variable/s on Prices',
        MBB: 'Impact of investment in the mobile sector after a % change in the selected policy variable/s on Penetration',
        GDP: 'Impact of investment in the mobile sector after a % change in the selected policy variable/s on GDP per capita',
      },
      chartsTitlesShort: {
        CAPEX: 'Impact on Mobile CAPEX',
        coverage: 'Impact on Coverage',
        prices: 'Impact on Prices',
        MBB: 'Impact on Penetration',
        GDP: 'Impact on GDP per capita',
      },
      chartsFooter: {
        aboutChart:
          'The graph/s show/s a projection of the impact of the adoption of regulatory policies on market' +
          'performance in the mobile sector over time (either in terms of increase in CAPEX, mobile coverage,' +
          'mobile broadband penetration, GDP per capita or decrease in the price of services). This is a generic' +
          'simulation based on econometric modelling and historic data for 145 countries over the period 2008-2019. | ',
      },
      chartLines: {
        broadbandPlan: 'Adoption of a National Broadband Plan',
        convergentLicenses: 'Adoption of converged licences',
        spectrumSharing: 'Allow spectrum sharing agreements',
        mobilePortability: 'Adoption of mobile number portability',
        opennessToForeign: 'Openness to foreign telecom operators',
        competitionAuthority: 'Existence of a competition authority',
        allSixPolicies: 'All six policies',
      },
      infoTitles: {
        broadbandPlan: 'Adoption of a National Broadband Plan. Indicates that a National Broadband Plan has been adopted.\nSource: ITU, ',
        convergentLicenses:
          'Adoption of convergent licenses. Indicates that a unified/ global ' +
          'licenses or general authorization/ simple notification regime is in place rather ' +
          'than service specific licenses.\nSource: ITU, ',
        spectrumSharing: 'Allowing spectrum sharing agreements. Indicates if active infrastructure sharing' + 'agreements for mobile operators are permitted (e.g., MVNO).\nSource: ITU, ',
        mobilePortability: 'Adoption of mobile number portability. Indicates if number portability is required from mobile operators.\nSource: ITU, ',
        opennessToForeign: 'Openness to foreign operators. Indicates if no restrictions are placed on foreign spectrum-based operators.\nSource: ITU, ',
        competitionAuthority: 'Presence of a competition authority. Indicates that a National Competition Authority exists.\nSource: ITU, ',
        allSixPolicies: '',
      },
      infoLinks: {
        broadbandPlan: 'https://www.itu.int/go/tracker',
        convergentLicenses: 'https://www.itu.int/go/tracker',
        spectrumSharing: 'https://www.itu.int/go/tracker',
        mobilePortability: 'https://www.itu.int/go/tracker',
        opennessToForeign: 'https://www.itu.int/go/tracker',
        competitionAuthority: 'https://www.itu.int/go/tracker',
        allSixPolicies: '',
      },
      infoLinkTexts: {
        broadbandPlan: 'ICT Regulatory Tracker',
        convergentLicenses: 'ICT Regulatory Tracker',
        spectrumSharing: 'ICT Regulatory Tracker',
        mobilePortability: 'ICT Regulatory Tracker',
        opennessToForeign: 'ICT Regulatory Tracker',
        competitionAuthority: 'ICT Regulatory Tracker',
        allSixPolicies: '',
      },
    },
    overallSector: {
      title: 'Telecom Sector',
      subtitle: 'What do you want to explore?',
      subtitle2: 'Select a policy variable and choose a % change',
      chartsTitles: {
        chartTitle: 'Impact of overall telecom investment after a % change in the selected policy variable/s',
      },
      chartsFooter: {
        aboutChart:
          'The graph shows an estimate of the impact of the adoption of regulatory policy on market performance ' +
          '(in terms of increase in CAPEX for the overall telecommunications sector). ' +
          'This is a generic simulation based on econometric modelling and historic data for 145 countries over the period 2008-2019. | ',
      },

      chartsCategories: {
        // CHART 1
        regulatoryAuthority: 'Regulatory Authority',
        regulatoryMandate: 'Regulatory Mandate',
        regulatoryRegime: 'Regulatory Regime',
        competitionFramework: 'Competition Framework',
        overall: 'ICT Regulatory Tracker (overall)',
        // CHART 2
        profitTax: 'Profit tax',
        bureaucraticBurden: 'Bureaucratic burden',
        OECDMembership: 'OECD membership',
        WTOMembership: 'WTO membership (years)',
      },
      infoTitles: {
        // CHART 1
        regulatoryAuthority:
          'Regulatory Authority is a composite metric of the features and the functioning of the ICT' + 'regulator and the existence of a national competition authority.\nSource: ITU, ',
        regulatoryMandate:
          'Regulatory Mandate is a composite metric referring to the entity in charge of' +
          'regulating specific areas: quality of services, licensing, interconnection rates and price regulation, spectrum,' +
          'universal service access, broadcasting, internet and IT.\nSource: ITU, ',
        regulatoryRegime:
          'Regulatory Regime is a composite metric referring to the adoption of good regulatory practices' +
          'in areas such as licensing, interconnection, quality of services, infrastructure sharing,' +
          'access regulation, and number portability.\nSource: ITU, ',
        competitionFramework:
          'Competition Framework is a composite metric based on the competition' +
          'level for different ICT market segments, regulatory definitions' +
          'for significant market power, and foreign participation.\nSource: ITU, ',
        overall:
          'ICT Regulatory Tracker is the overall benchmark including the different' +
          'composite metrics: Regulatory Authority, Regulatory Mandate, Regulatory' +
          'Regime and Competition Framework.\nSource: ITU, ',
        // CHART 2
        profitTax: 'Profit tax. Measures the profit tax imposed to the business sector as a percentage of commercial profits.\nSource: World Bank, ',
        bureaucraticBurden: 'Bureaucratic burden is defined by using as a proxy the time required to start a business (days).\nSource: World Bank, ',
        OECDMembership: 'OECD membership. Variable that indicates membership of the country to the OECD.\nSource: ',
        WTOMembership: 'Membership to WTO (years). Set the years a country has been a signatory of WTO membership.\nSource: ',
      },
      infoLinks: {
        regulatoryAuthority: 'https://www.itu.int/go/tracker',
        regulatoryMandate: 'https://www.itu.int/go/tracker',
        regulatoryRegime: 'https://www.itu.int/go/tracker',
        competitionFramework: 'https://www.itu.int/go/tracker',
        overall: 'https://www.itu.int/go/tracker',
        // CHART 2
        profitTax: 'https://databank.worldbank.org/source/world-development-indicators',
        bureaucraticBurden: 'https://databank.worldbank.org/source/world-development-indicators',
        OECDMembership: 'http://www.oecd.org/about/members-and-partners/',
        WTOMembership: 'https://www.wto.org/english/thewto_e/whatis_e/tif_e/org6_e.htm',
      },
      infoLinkTexts: {
        regulatoryAuthority: 'ICT Regulatory Tracker',
        regulatoryMandate: 'ICT Regulatory Tracker',
        regulatoryRegime: 'ICT Regulatory Tracker',
        competitionFramework: 'ICT Regulatory Tracker',
        overall: 'ICT Regulatory Tracker',
        // CHART 2
        profitTax: 'World Development Indicators',
        bureaucraticBurden: 'World Development Indicators',
        OECDMembership: 'OECD',
        WTOMembership: 'WTO',
      },
    },
  },
  charts: {
    tooltips: {
      generationTooltip: 'Generations 1-4 track the evolution of the maturity of ICT regulation. See <link1> Note on Methodology </link1> for more details.',
      policyAreasTooltip:
        'The policy areas here are based on composite metrics for Regulatory Authority, Regulatory Mandates, Regulatory Regime and Competition Framework. See <link1> Note on Methodology </link1> for more details.',
      indicatorsTooltip: '50+ individual indicators tracking the maturity of policy and regulatory frameworks for the ICT sector. See <link1> Note on Methodology </link1> for more details.',
    },
    chartsFooterNote: 'Note: The tooltip shows the average scores for the selected region.',
    builderNote: 'The trends here are based on the ICT Regulatory Tracker. Please check the <link1> Note on Methodology </link1> for more details',
    description: `<str>What do the ICT Regulatory Tracker and the Generations of regulation concept do?</str><br/>
      <p>
      ITU regulatory metrics sets out clearly the levels of maturity of regulatory frameworks for the ICT sector. 
      The ICT Regulatory Tracker pinpoints the changes taking place in the ICT regulatory environment. 
      It facilitates benchmarking and the identification of trends in ICT legal and regulatory frameworks. </p>
      <p>
      The Tracker does not measure the quality, the level of implementation or the performance of regulatory frameworks in place, but records their existence and features. 
      It helps track progress and identify gaps in regulatory frameworks, making the case for further regulatory reform towards achieving a vibrant and inclusive ICT sector.
      </p>
      \nFor the details, see  <link1>Note on Methodology</link1>`,
    benchmarkDescription: `
      <str>What is the G5 benchmark and the level of maturity of national frameworks?</str><br/>
      <p>
        The Benchmark of Fifth Generation Collaborative Digital Regulation, or G5 Benchmark, is a data- based tool featuring an extensive
        collaborative governance component and a focus on legal instruments for digital enablers. The G5 Benchmark was designed based on
        GSR19 Best Practice Guidelines and together with the <link1>ICT Regulatory Tracker</link1>, serves as a compass for regulators on their journey of
        digital transformation, helping establish roadmaps towards regulatory excellence and a thriving digital economy.
      </p>
      <p>
        Based on the G5 Benchmark, countries are classified as Limited, to Transitioning, to Advanced, to Leading, in terms of the maturity of
        their national policy, legal and governance framework for digital transformation.
      </p>
      <p>
        For more details, see the <link2>concepts explained</link2> and the <link3>Note on methodology</link3>.
      </p>
      <p>
        For in-depth analysis of digital policy, regulatory and governance trends, read the <link4>Global Digital Regulatory Outlook 2023</link4>.
      </p>
    `,
    journeyDescription: `<str>Good regulation broadens access and ignites markets</str><br/>
      <br/>
      Good regulation has impact. Analysis based on the ICT Regulatory Tracker has clearly shown just how effectively higher generations of regulation are helping 
      mobile and fixed broadband penetrate further for digital services. 
      G4 in particular has proven a powerful engine for Internet access growth. 
      The case of mobile broadband demonstrates the crucial role played by good regulatory frameworks. 
      The regulatory environment also powerfully determines how well fixed broadband markets develop.<br/>
      <br/>
      Our analysis above plots mobile and fixed-broadband penetration and Internet users growth of countries by <link1>G1, G2, G3 and G4</link1>, 
      from years 2008 to 2020 based on the <link2>ICT Regulatory Tracker</link2> and the <link3>World Telecommunication Indicators Database</link3> 
      These ITU datasets are based on self-reported information gathered via official ITU surveys to Member State administrations and in some cases, 
      have been complemented through desktop research based on official sources.
      <br/>
      <br/>
      For further analysis, check the <link4>Global ICT Regulatory Outlook 2020</link4>
      <br/>
      <br/>
      To discover the key ingredients of best practice regulation for the digital transformation, explore <link5>the Series of Best Practice Guidelines of the Global Symposium for Regulators</link5>
      `,
    compareTabtitle1: 'What do you want to see ?',
    compareTabtitle2: 'Compare countries, regions, income groups and levels of development on generations of regulation, policy areas and regulatory indicators',
    compareTabtitle3: 'Pick an area',
    region: 'Region',
    regTracker2022: 'ICT Regulatory Tracker, 2022',
    benchmark2023: 'G5 Benchmark, 2023',
  },
  countryInfo: {
    region: 'Region',
    mobileBroadband: 'Mobile broadband',
    mobileBroadbandTooltip: 'Total number of active mobile broadband users',
    fixedBroadband: 'Fixed broadband',
    fixedBroadbandTooltip: 'Total number of fixed broadband subscribers',
    internetUsersPerCapita: 'Internet users per capita',
    internetUsersPerCapitaTooltip: 'Internet users penetration',
  },
  benchmark: {
    about: {
      title: 'Introduction',
      chapter_0: {
        title: 'The Benchmark for Fifth Generation Digital Collaborative Regulation, or the G5 Benchmark',
        content:
          'The G5 Benchmark was launched in 2020 and quickly became the gold standard for fast-track collaborative, cross-sector Digital regulation. It was quickly well-received – and has proved itself a practical navigator for country regulators, helping them establish roadmaps towards G5 Digital collaborative regulation excellence and inclusive digital transformation across all sectors of the economy.',
      },
      chapter_1: {
        title: 'The first pilot edition and the making of the now established G5 Benchmark',
        content: `The pilot version released in 2020 covered more than 80 countries and
        evaluated regulatory frameworks against 25 measurable indicators, clustered
        across three pillars. Each country could score itself out of a 50-point
        maximum. The simple scoring methodology has proved powerful and popular
        with regulators, generating discussion and interest – and importantly very real
        in-country focus on how to move forward along the Digital collaborative
        regulation pathway.
        `,
      },
      chapter_8: {
        title: 'Why refining the G5 Benchmark pilot version?',
        content: `
        Tuning the Benchmark and its methodology has been a consultative, iterative
        process. Our 2020 pilot version benefited from feedback offered by regulators,
        regulatory experts and data scientists – this led to the expansion and
        rebalancing to better respond to the needs of national decision-makers. The
        updated Benchmark was then reviewed by an external independent expert
        board bringing together academia, international organizations, think tanks,
        regulatory associations and industry associations. This independent review
        provided final revisions and marked the coming of age for the G5 Benchmark.
        The final expert report of the Review Board is available at the bottom of this
        page.`,
      },
      chapter_2: {
        title: 'What’s in the G5 Benchmark? A quick look',
        content: `The now established G5 Benchmark takes data from 193 countries and
        expands to cover four pillars, with 70 indicators taken into account. Each
        country can score itself, with a 100-point maximum.
        <br />
        <br />
        The G5 Benchmark maintains its signature practicality and simplicity, offering
        regulators a powerful, straightforward readout on where the country is on the
        collaborative Digital regulation pathway.
        <br />
        <br />
        The now established G5 Benchmark is structured around four pillars:
        <br />
        <ul>
        
        <li>  <strong>Pillar I: National collaborative governance</strong> measures the breadth and
        depth of cross-sector collaboration between the ICT regulator, peer regulators
        and policy-makers. It factors in the institutional set-up (agencies and their
        mandates) as well as practices around regulatory collaboration, formal and
        informal, across 16 areas, including consumer protection, spectrum
        management, education and e-waste.</li>
        <br />
        <li>  <strong>Pillar II: Policy design principles</strong> focus on the design of frameworks and
        their coherence. As all sectors’ regulation shifts from rules to principles, new
        elements have become paramount in ensuring that regulatory processes and
        policy implementation are delivering as they should – from applying tools for
        evidence-based decision-making, to providing space for regulatory
        experimentation, to strengthening the accountability of multistakeholder policy
        initiatives, to ethics.</li>
        <br />
        <li>  <strong>Pillar III: Digital development toolbox</strong> focuses on the tools needed by
        regulators to stimulate development of a sustainable digital economy. It
        considers new consumer needs, business models and market dynamics. The
        G5 toolbox spans areas such as cybersecurity, data protection, emergency
        telecommunications and cross-sector infrastructure sharing. The toolbox also
        includes universal instruments geared towards the achievement of mid- to
        long-term social and economic goals – such as youth employment and
        sustainable consumption and production – where digital has a central role to
        play.</li>
        <br />
        <li>  <strong>Pillar IV: Digital economic policy agenda</strong> features country policies and
        interventions for promoting the digital economy, entrepreneurship and
        investment. The areas covered range from an innovation framework to digital
        transformation to sector taxation and adherence to international and regional
        integration initiatives.</li>
        </ul>
        <br/>
        The G5 Benchmark features a total of 70 indicators focused on policy and
        regulatory frameworks that will best enable the digital transformation.
        According to their score, each of 193 countries is associated with one of four
        levels of national policy and regulatory framework maturity – these are
        Leading, Advanced, Transitioning, and Limited.
        <br/>
        <br/>
        <strong>Levels of maturity of national enabling environment for digital markets (G5)</strong>
        <br/>
        <table />
        <br/> 
        Note: the rational for the scoring is available in the G5 Benchmark: List of indicators and
        scoring methodology here below.
        `,
      },
      chapter_3: {
        title: 'Sources and validation of data',
        content: `The G5 Benchmark is primarily based on self-reported information gathered
        via official ITU surveys, datasets compiled by
        international organizations, as well as desktop research based on official
        government sources and direct outreach to national telecommunication and
        ICT regulatory authorities. Data received via official ITU surveys has been verified to the extent reasonably feasible and, when
        necessary, aligned with publicly available official government sources, in view
        of ensuring high level of coherence and robustness of the dataset. The
        research team has strived to ensure the accuracy of data to the greatest
        extent possible; nevertheless, in rare cases it may be incomplete or include
        irregular or odd values.`,
      },
      chapter_4: {
        title: 'Disclaimers',
        content: `
        The G5 Benchmark is based on self-reported information gathered via official ITU Surveys to Member States 
        institutions, datasets compiled by international organizations as well as desktop research based on official government sources 
        and direct outreach to national telecom/ICT regulatory authorities. Official data received from Member States has been verified 
        to the extent reasonably feasible. The research team endeavours to ensure the accuracy of data to the greatest extent possible; 
        nevertheless, we do not warrant its completeness, nor we exclude the possibility of irregular or odd values in rare cases.
        <br> 
        <br>
        The designations employed and the presentation of material in this information product do not imply the expression of any opinion 
        whatsoever on the part of the International Telecommunication Union (ITU) concerning the legal or development status of any country, 
        territory, city or area or of its authorities, or concerning the delimitation of its frontiers or boundaries.
        <br>
        <br>
        All reasonable precautions have been taken by ITU to verify the information contained in this website. However, the published material 
        is being distributed without warranty of any kind, either expressed or implied. The responsibility for the interpretation and use of 
        the material lies with the reader. The opinions, findings and conclusions expressed in this publication do not necessarily reflect 
        the views of ITU or its membership.
        `,
      },
      chapter_5: {
        title: 'Keep reading',
        content: `
        <ul>
          <li>
            <link2>Benchmark of fifth-generation collaborative digital regulation 2023 – Global and regional trends</link2>
          </li>
          <li>
          <link0><i>Expert Report of the G5 Benchmark,</i></link0> March 2022.
            Note: This final version of the Expert Report integrates
            recommendations of the Review Board of the G5 Benchmark. The G5
            Benchmark Country profiles available here are based on the
            methodology as contained in this report.
          </li>
          </li>
          <li>
            <link1>G5 Benchmark: List of indicators and scoring methodology</link1>
          </li>
        </ul> 
        `,
      },
      chapter_6: {
        title: 'Copyright notice',
        content: `<i>G5 Benchmark <link0>gen5.digital</link0>, © 2023, ITU, some rights reserved.</i>
        <br/>
        <link1><image0/></link1>
        <br/>
        The data on this platform (including the datasets and data visualization) is licensed to the public through a 
        Creative Commons Attribution-Non-Commercial-ShareAlike 3.0 IGO license. Under the <link1>terms of this licence</link1>, you may copy, 
        redistribute, and adapt the Data for non-commercial purposes, provided the work is appropriately cited.
        <br/>
        <br/>
        Suggested citation: International Telecommunication Union, G5 Benchmark, <link2>gen5.digital</link2>. 
        It is recommended to indicate the access date, since the data is updated on a regular basis. In any use of the data, 
        there should be no suggestion that ITU endorse any specific organization, products, or services. 
        The unauthorized use of the ITU name or logo is not permitted.
        `,
      },
      chapter_7: {
        title: 'Process for updating country profiles of ITU Administrations',
        content: `The G5 Benchmark is updated every two years based on submissions by ITU Member State
        administrations and national ICT regulators to the ITU World Telecommunication/ICT Regulatory
        Survey. Country submissions go through a standard verification and validation process carried out by
        the ITU Secretariat before they are published on the <link0>ITU Datahub</link0>, the ITU data dissemination
        platform.
        <br/>
        
        <br/>
        In order to ensure that the G5 Benchmark is as complete as possible, the ITU Secretariat
        may, in some cases, carry out additional research based exclusively on official government sources
        to complete data gaps and improve the completeness, accuracy and overall robustness of the
        dataset.
        <br/>  
        <br/>  
        A review of their country profiles can be done at any time following the steps below.
        <br/>
        <ul>
          <li>
            ITU Member State Administrations and national ICT regulators can submit a request for review <link1>by email</link1> indicating the
            reason for the request and any new elements to be considered.
          </li>
          <li>
            ITU Secretariat will carry out the review of the new elements in the three months following
            the submission of the request. The Secretariat reserves the right to request additional
            references, documentation or explanation on specific points in order to be in a position to
            complete the review. A final report is then produced, which may result in a change of
            individual indicators or scores (either positive or negative), or in the confirmation of the data
            on the country profile (no change).
          </li>
          <li>
            The report is submitted to the country for information and is considered final, until the next
            regular update of the G5 Benchmark.
          </li>
          <li>
            If changes have been made to individual indicators and scores, these will be reflected online
            in the month following the review completion at the latest.
          </li>
        </ul>
        For the purposes of the review, new elements should meet the following requirements:
        <br/>
        <ul>
        <li>
          Comply with the definition of the respective indicators and targets, as per the established
          methodology;
        </li>
        <li>
          Refer to legal instruments, policies, institutional arrangements, initiatives or else already in
          force at the date of the latest dataset available (e.g., no draft regulations or pending
          approvals);
        </li>
        <li>
          Accessible online or alternatively, submitted to the Secretariat as searchable PDF or
          Microsoft Word documents in their entirety (no scanned or incomplete documents). In case
          such documents are not intended for public distribution, this should be clearly marked, and
          they will only be consulted by the Secretariat for the purposes of the review and not
          disclosed to third parties.
        </li>
      </ul>
        `,
      },
    },
    concepts: {
      chapter_0: {
        title: 'The five generations of regulation',
        content: `
          Regulators and policy-makers need evidence to inform their decisions as they seek to build a
          competitive, inclusive and resilient digital economy – evidence that helps them compare practices
          across countries and regions against a universal reference frame for regulatory excellence and good
          governance.
          <br/> <br/>
          The five generations of regulation provide a high-level conceptual framework to understand trends
          and track progress in the development of telecom, ICT and digital policy, legal and governance
          frameworks. Generations 1 through 4 track the maturity of telecom markets. Generation 5 (Gen 5)
          encompasses the evolution of national digital markets, from limited to transitioning and advanced to
          leading.
          <br/> <br/>
          The ‘generations of regulation’ model sets out, at a glance and across five clearly differentiated
          generations, how policy and regulation have evolved over recent decades – from a narrow focus on
          telecom, to the broader perspective on ICTs and then onto the gold standard for collaborative digital
          governance. The model has been built with the global community of ICT regulators – annually tuned
          and enhanced since 2003 by the <link0>Global Symposium for Regulators</link0> (GSR). The gold standard for
          digital regulation, Gen 5, embodies the guidance of this expert, high-level community and is framed
          under the premise ‘collaboration across sectors, cooperation across borders, and engagement across
          the board’.
          <br/><br/>
          One of a kind, Gen 5 models how conducive policy, regulatory and governance frameworks for the
          digital transformation unfold, from Limited, to Transitioning, to Advanced, to Leading.
          <br/>
          <image1/> 
          <br/><br/>
          The model is strategic and practical, enabling analysis of and planning for the evolution of national
          digital governance capacity and regulation. Two associated tools – the ICT Regulatory Tracker and
          the G5 Benchmark – underwrite the model in assessing the state of maturity of national legal
          frameworks and their governance, and tracking their progress over time.
          `,
      },
      chapter_1: {
        title: 'Benchmarking tools',
        content: `
        Building on the Generations of regulation model, ITU has developed two complementary
        benchmarking tools, the ICT Regulatory Tracker and the G5 Benchmark. These help understand
        global trends and identify policy and regulatory gaps. The ICT Regulatory Tracker tracks the evolution
        of generations of telecommunication sector reform. In parallel, the G5 Benchmark charts the digital
        transformation journey from its inception to building a thriving digital society and helps countries
        establish roadmaps to navigate the digital transformation.
        <br/><br/>
        As digital markets integrate into core telecom infrastructure, legal, policy and regulatory frameworks
        for telecom and digital have evolved in parallel, at different speeds in countries and across regions.
        <br/>
        <ul>
          <li>
            In the past, two separate frameworks have addressed issues associated with telecom and digital
            ecosystems as each of these areas mature – and two different tools are powerful aids in assessing
            them – the ICT Regulatory Tracker for telecom and the G5 Benchmark for digital markets.
          </li>
          <li>
            Increasingly, a new generation of such frameworks, Gen 5, enables digital ecosystems to gain
            traction and is helping accelerate progress across a broad range of development goals.
          </li>
          <li>
            Using both tools mentioned above generates actionable insights and puts national decision-makers
            in the driving seat as they navigate the digital transformation of their economies and societies.
        </li>
      </ul> 
      <br/>
      This integrated, augmented approach to the assessment of digital policy, development and
      implementation based on best practices – and their localization – is rapidly becoming the gold
      standard for policy and regulation in the digital transformation.
        `,
      },
      chapter_2: {
        title: 'The G5 Benchmark',
        content: `
        The Benchmark of Fifth Generation Collaborative Digital Regulation, or G5 Benchmark, is a data-
        based tool featuring an extensive collaborative governance component and a focus on legal
        instruments for digital enablers. The G5 Benchmark was designed based on GSR19 Best Practice
        Guidelines and together with the ICT Regulatory Tracker, serves as a compass for regulators on their
        journey of digital transformation, helping establish roadmaps towards regulatory excellence and a
        thriving digital economy.
        <br/>
        The full Benchmark methodology is available on <link0>this platform.</link0>
        `,
      },
      chapter_3: {
        title: 'The ICT Regulatory Tracker',
        content: `
        The ICT Regulatory Tracker is a conceptually sound, statistically coherent and robust data-based tool for
        monitoring the changes taking place in the ICT environment. It allows associating countries to Generations
        1 through 4 and supports national regulators and decision-makers in pinpointing areas for further reforms.
        <br />
        The full Tracker methodology is available on <link0>this platform.</link0>
        `,
      },
      chapter_4: {
        title: 'The Global ICT Regulatory Outlook',
        content: `
        The <link0>Global ICT Regulatory Outlook series</link0> launched in 2017 shares unique, focused research and
        offers both evidence and practical advice to support regulators who have embarked on their journey
        to digital transformation, leveraging collaborative digital regulation. The evolving focus of the series
        led to rebranding it, as from this edition, into the Global Digital Regulatory Outlook. The analysis is
        built on ITU’s unique set of benchmarks and evidence-informed frameworks, demonstrating how they
        can be leveraged by national decision-makers in their strategic and day-to-day work.
        <br />
        `,
      },
      chapter_5: {
        title: 'The Collaborative digital governance country reviews',
        content: `
        A series of country reviews and case studies has sought to articulate the benefits of G5 regulation at
        country level, and anchor these benefits in experience and evidence.
        <br/>
        Each of the collaborative regulation country reviews offers a high-value, authoritative analysis of the
        country regulatory landscape and a clear-eyed view of the path ahead towards G5 regulation. These
        country reviews capture hard-won gains and provide pointers of immense value to other countries
        eyeing a similar path. Equally they deliver a practical and inspiring message of empowerment, of
        overcoming resistance and securing acceptance of the work’s value and of what it can deliver.
        <br/>
        The country reviews completed to date are available <link0>on this platform.</link0>
        `,
      },
      chapter_6: {
        title: 'Unified Framework',
        content: `
        The Unified Framework provides a suite of benchmarks and a blueprint for policy, regulation and governance enabling the digital 
        transformation and a global assessment of the level of national capacities and readiness for the digital transformation.
        The analysis based on the Unified Framework and its nine thematic benchmarks enables regulators and policy makers to make sense 
        of their own situation compared to peers, while pinpointing strengths, gaps and priorities for future reforms. National blueprints 
        built on the Framework allow crafting regulatory roadmaps and custom transformation journeys.
        The full Unified Framework methodology is available in the <link0>Global Digital Regulatory Outlook 2023. </link0>
        `,
      },
    },
  },
  unifiedFramework: {
    about: {
      title: 'Unified Framework',
      chapter_0: {
        title: 'Introduction',
        content: `
          <span>
          Digital transformation of economies and societies requires an enabling environment
          for both traditional telecom and digital markets and the enablers for digitization
          across economic sectors. A diverse set of complementary policy and regulatory tools
          and a wide range of governance structures can be leveraged to deliver such an
          environment, as well as universal meaningful connectivity and digital public services,
          while boosting digital innovation, value creation and economic opportunities.
          <span />
          <p>
          In order to take a holistic view of the enabling environment for digital transformation,
          we have built a unified framework combining ITU’s established tools for assessing
          policy, regulation and governance in telecom and in digital markets, the ICT
          Regulatory Tracker and the G5 Benchmark.
          <p />
        `,
      },
      chapter_1: {
        title: 'What is the Unified Framework?',
        content: `
          <span>
          The Unified Framework provides a blueprint for policy, regulation and governance
          enabling the digital transformation and a global assessment of the level of national
          capacities and readiness for the digital transformation.
          <span />
          <p>
          The analysis based on the Unified Framework and its nine thematic benchmarks
          enables regulators and policy makers to make sense of their own situation compared
          to peers, while pinpointing strengths, gaps and priorities for future reforms. National
          blueprints built on the Framework allow crafting regulatory roadmaps and custom
          transformation journeys.
          <p />
        `,
      },
      chapter_8: {
        title: 'Scope, structure and composition',
        content: `
          <span>
          The Framework features nine thematic benchmarks based on a total of 119
          indicators <link0>(see full list)</link0>
          </span>
          <ul>
            <li>Benchmark 1: National digital policy agenda</li>
            <li>Benchmark 2: Regulatory capacity</li>
            <li>Benchmark 3: Good governance</li>
            <li>Benchmark 4: Collaborative governance</li>
            <li>Benchmark 5: Stakeholder engagement</li>
            <li>Benchmark 6: Legal instruments for ICT/telecom markets</li>
            <li>Benchmark 7: Legal instruments for digital markets</li>
            <li>Benchmark 8: Market rules</li>
            <li>Benchmark 9: Regional and international collaboration</li>
          </ul>
          <image0/>
          <br>
          <br>
        `,
      },
      chapter_2: {
        title: 'Sources and validation of data',
        content: `
        The Unified Framework is primarily based on self-reported information gathered via
        official ITU surveys, datasets compiled by international organizations, as well as
        desktop research based on official government sources and direct outreach to
        national telecommunication and ICT regulatory authorities. Data received via official
        ITU surveys has been verified to the extent reasonably feasible and, when
        necessary, aligned with publicly available official government sources, in view of
        ensuring high level of coherence and robustness of the dataset. The research team
        has strived to ensure the accuracy of data to the greatest extent possible;
        nevertheless, in rare cases it may be incomplete or include irregular or odd values.
        <br>
        <br>
        `,
      },
      chapter_6: {
        title: 'Copyright notice',
        content: `<i>Unified Framework <link0>gen5.digital</link0>, © 2024, ITU, some rights reserved.</i>
        <br/>
        <link1><image0/></link1>
        <br/>
        The data on this platform (including the datasets and data visualization) is licensed to the public through a 
        Creative Commons Attribution-Non-Commercial-ShareAlike 3.0 IGO license. Under the <link1>terms of this licence</link1>, you may copy, 
        redistribute, and adapt the Data for non-commercial purposes, provided the work is appropriately cited.
        <br/>
        <br/>
        Suggested citation: International Telecommunication Union,  Unified Framework, <link2>gen5.digital</link2>. 
        It is recommended to indicate the access date, since the data is updated on a regular basis. In any use of the data, 
        there should be no suggestion that ITU endorse any specific organization, products, or services. 
        The unauthorized use of the ITU name or logo is not permitted.
        <br>
        <br>
        `,
      },
      chapter_4: {
        title: 'Disclaimers',
        content: `
        The Unified Framework is based on self-reported information gathered via official ITU Surveys to Member States 
        institutions, datasets compiled by international organizations as well as desktop research based on official government sources 
        and direct outreach to national telecom/ICT regulatory authorities. Official data received from Member States has been verified 
        to the extent reasonably feasible. The research team endeavours to ensure the accuracy of data to the greatest extent possible; 
        nevertheless, we do not warrant its completeness, nor we exclude the possibility of irregular or odd values in rare cases.
        <br> 
        <br>
        The designations employed and the presentation of material in this information product do not imply the expression of any opinion 
        whatsoever on the part of the International Telecommunication Union (ITU) concerning the legal or development status of any country, 
        territory, city or area or of its authorities, or concerning the delimitation of its frontiers or boundaries.
        <br>
        <br>
        All reasonable precautions have been taken by ITU to verify the information contained in this website. However, the published material 
        is being distributed without warranty of any kind, either expressed or implied. The responsibility for the interpretation and use of 
        the material lies with the reader. The opinions, findings and conclusions expressed in this publication do not necessarily reflect 
        the views of ITU or its membership.
        <br>
        <br>
        `,
      },
      chapter_5: {
        title: 'Keep reading',
        content: `
        <span>
        <link0>Methodology of the Unified Framework</link0> 
        </span>
        <p>
        <link1>Global Digital Regulation Outlook 2023</link1>
        </p>
        `,
      },
    },
  },
};

import { START_LOADING, STOP_LOADING } from '../types';

const stopLoading = () => (dispatch) => {
  dispatch({ type: STOP_LOADING });
};

const startLoading = () => (dispatch) => {
  dispatch({ type: START_LOADING });
};

export { startLoading, stopLoading };

const CLOSE_DIALOG = 'CLOSE_DIALOG';
const SHOW_DIALOG = 'SHOW_DIALOG';
const START_LOADING = 'START_LOADING';
const STOP_LOADING = 'STOP_LOADING';
const SET_ERROR = 'SET_ERROR';

// CMS

const LOG_IN_LOADING = 'LOG_IN_LOADING';
const LOG_IN_ERROR = 'LOG_IN_ERROR';
const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';
const SET_VIEW1_TABLE = 'SET_VIEW1_TABLE';
const SAVE_VIEW1_TABLE = 'SAVE_VIEW1_TABLE';

const SET_VIEW2_TABLE = 'SET_VIEW2_TABLE';
const SAVE_VIEW2_TABLE = 'SAVE_VIEW2_TABLE';

const SET_VIEW3_TABLE = 'SET_VIEW3_TABLE';
const SAVE_VIEW3_TABLE = 'SAVE_VIEW3_TABLE';

const SET_SUB_VIEW2_TABLE = 'SET_SUB_VIEW2_TABLE';
const SAVE_SUB_VIEW2_TABLE = 'SAVE_SUB_VIEW2_TABLE';

const SET_SUB_VIEW3_TABLE = 'SET_SUB_VIEW3_TABLE';
const SAVE_SUB_VIEW3_TABLE = 'SAVE_SUB_VIEW3_TABLE';

const SET_RAWDATA_VIEW2_TABLE = 'SET_RAWDATA_VIEW2_TABLE';
const SAVE_RAWDATA_VIEW2_TABLE = 'SAVE_RAWDATA_VIEW2_TABLE';

const SET_RAWDATA_VIEW3_TABLE = 'SET_RAWDATA_VIEW3_TABLE';
const SAVE_RAWDATA_VIEW3_TABLE = 'SAVE_RAWDATA_VIEW3_TABLE';

const SET_TABLE_DUPLICATE = 'SET_TABLE_DUPLICATE';

const DUPLICATE_VIEW2 = 'DUPLICATE_VIEW2';
const DUPLICATE_VIEW3 = 'DUPLICATE_VIEW3';
const DUPLICATE_COUNTRY_VIEW2 = 'DUPLICATE_COUNTRY_VIEW2';
const DUPLICATE_COUNTRY_VIEW3 = 'DUPLICATE_COUNTRY_VIEW3';
const DUPLICATE_RAW_DATA_VIEW2 = 'DUPLICATE_RAW_DATA_VIEW2';
const DUPLICATE_RAW_DATA_VIEW3 = 'DUPLICATE_RAW_DATA_VIEW3';

export {
  LOG_IN_LOADING,
  LOG_IN_ERROR,
  LOG_IN_SUCCESS,
  CLOSE_DIALOG,
  SHOW_DIALOG,
  START_LOADING,
  SET_ERROR,
  STOP_LOADING,
  SET_VIEW1_TABLE,
  SAVE_VIEW1_TABLE,
  SET_VIEW2_TABLE,
  SAVE_VIEW2_TABLE,
  SET_VIEW3_TABLE,
  SAVE_VIEW3_TABLE,
  SET_SUB_VIEW2_TABLE,
  SAVE_SUB_VIEW2_TABLE,
  SET_SUB_VIEW3_TABLE,
  SAVE_SUB_VIEW3_TABLE,
  SET_RAWDATA_VIEW2_TABLE,
  SAVE_RAWDATA_VIEW2_TABLE,
  SET_RAWDATA_VIEW3_TABLE,
  SAVE_RAWDATA_VIEW3_TABLE,
  SET_TABLE_DUPLICATE,
  DUPLICATE_VIEW2,
  DUPLICATE_VIEW3,
  DUPLICATE_COUNTRY_VIEW2,
  DUPLICATE_COUNTRY_VIEW3,
  DUPLICATE_RAW_DATA_VIEW2,
  DUPLICATE_RAW_DATA_VIEW3,
};

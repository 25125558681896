import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Button, Snackbar } from '@material-ui/core';
import XLSX from 'xlsx';
import { useDispatch } from 'react-redux';
import MemorizedCell from './Cell';
import styles from './sheet.module.scss';
import { startLoading, stopLoading } from '../../redux/actions/LoadingActions';
import { createRows, isObject } from './helpers';

function Sheet({ rowHeight, offsetBuffer, data, cellTypes, handleOnBlur, actionDefs, setter, duplicateTable, handleSaveLine, isScore }) {
  const initialselecteds = {
    startRow: null,
    endRow: null,
    startCol: null,
    endCol: null,
  };

  const contRef = useRef();
  const containerRef = useRef();
  const wrapperRef = useRef();

  const columnWidth = 100;

  // const getCellType = (i, j) => cellTypes?.[j] ?? { type: 'input' };

  const dispatch = useDispatch();

  const customCellStyles = useRef({});

  const [popUp, setPopUp] = useState({ open: false, message: '' });
  // const [virtualizedGrid, setVirtualizedGrid] = useState(null);
  // const virtualizedGridRef = useRef([[]]);

  const [visibleRows, setVisibleRows] = useState(40);
  const [visibleCols, setVisibleCols] = useState(20);
  // const [showing, showing = ] = useState({
  //   startIndex: 0,
  //   endIndex: visibleRows,
  //   startIndexCol: 0,
  //   endIndexCol: visibleCols,
  // });

  const [showing, setShowing] = useState({
    startIndex: 0,
    endIndex: visibleRows,
    startIndexCol: 0,
    endIndexCol: visibleCols,
  });
  const { endIndex, startIndexCol, endIndexCol, startIndex } = showing;

  const [selectedCells, setSelectedCells] = useState(initialselecteds);
  const [rows, setRows] = useState([]);

  // const  rowsRef = useRef(data);
  const isDown = useRef(false);
  const scrollingInterval = useRef(null);

  // const rows = rowsRef.current;

  // const getHeader = (j) => {
  //   let res = '';
  //   const count = Math.floor(j / 26);
  //   for (let i = 0; i < count; i++) {
  //     res += String.fromCharCode(65 + i);
  //   }
  //   res += String.fromCharCode(65 + (j - count * 26));
  //   return res;
  // };

  const handleMouseEnterCell = useCallback(
    (e, cell) => {
      if (isDown.current) {
        const selectedCellClone = { ...selectedCells };
        const [i, j] = cell.id.split('_').map((item) => +item);
        selectedCellClone.endRow = i;
        selectedCellClone.endCol = j;
        setSelectedCells(selectedCellClone);
      }
    },
    [selectedCells]
  );

  // const createRows = (n, m, startIndex = 0, data, isAddition = false) => {
  //   const initialRows = [{}];
  //   const colSize = data?.length || n;
  //   const rowSize = data.length && m < Object.keys(data[0]).length ? Object.keys(data[0]).length : m;
  //   for (let i = 0; i < colSize; i++) {
  //     if (!Array.isArray(initialRows[i])) {
  //       initialRows[i] = {};
  //     }
  //     if (data.length) {
  //       Object.entries(data?.[i]).forEach(([key, value], j) => {
  //         initialRows[i][key] = {
  //           value,
  //           id: `${i + startIndex}_${j}`,
  //           type: startIndex <= 0 && i === 0 ? 'column' : 'cell',
  //           cellType: { type: 'input' },
  //           // cellType: getCellType(i, j),
  //         };
  //       });
  //     } else {
  //       for (let j = 0; j < rowSize; j++) {
  //         initialRows[i][j] = {
  //           id: `${i + startIndex}_${j}`,
  //           value: data[i]?.[j] ?? '',
  //           type: startIndex <= 0 && i === 0 ? 'column' : 'cell',
  //           cellType: { type: 'input' },
  //           // cellType: getCellType(i, j),
  //         };
  //       }
  //     }
  //   }
  //
  //   if (actionDefs) {
  //     initialRows.forEach((item, i) => {
  //       return i || isAddition
  //         ? {
  //             ...item,
  //             type: 'cell',
  //             cellType: { type: 'action' },
  //             actionDefs,
  //           }
  //         : {
  //             ...item,
  //             value: 'Action',
  //             type: 'column',
  //             cellType: { type: 'input' },
  //             // cellType: getCellType(i, item.length),
  //           };
  //     });
  //   }
  //   return initialRows;
  // };

  const handleAddRow = (count = 1) => {
    // const rowsClone = _.cloneDeep(rows);
    const addition = actionDefs ? -1 : 0;
    const newRows = createRows(count, Object.keys(rows[0]).length + addition, rows.length, [], true, actionDefs);
    rows.push(...newRows);

    setTimeout(() => {
      containerRef.current.scrollBy({
        top: containerRef.current.scrollHeight + rowHeight * 2,
        left: 0,
        behavior: 'smooth',
      });
    });
  };

  const isInRange = useCallback((start, end, i) => {
    const min = start < end ? start : end;
    const max = start + end - min;
    return typeof i === 'number' && typeof start === 'number' && typeof end === 'number' && i >= min && i <= max;
  }, []);

  // useEffect(() => {
  //   rowsRef.current = createRows(50, 50, 0, data, true, actionDefs);
  // }, [data]);

  const handleDown = () => {
    isDown.current = true;
  };

  const handleUp = () => {
    isDown.current = false;
  };

  const handleCellValueChange = useCallback(
    (e, cell, i, j) => {
      if (isObject(rows[i][j])) {
        if (isScore) {
          rows[i][j].value.score = e.target.value;
        } else {
          rows[i][j].value.response = e.target.value;
        }
      } else {
        rows[i][j].value = e.target.value;
      }
      if (setter) {
        const newData = _.cloneDeep(data);
        setter(newData);
      }
    },
    [rows]
  );

  useEffect(() => {
    setVisibleRows(containerRef.current.scrollHeight / rowHeight);
    setVisibleCols(containerRef.current.scrollWidth / columnWidth);
  }, [containerRef]);

  const copyRangeCells = async ({ startCol, startRow, endCol, endRow }) => {
    if (startCol > endCol) [startCol, endCol] = [endCol, startCol];
    if (startRow > endRow) [startRow, endRow] = [endRow, startRow];
    let clipboard = '';
    for (let i = startRow; i <= endRow; i++) {
      for (let j = startCol; j <= endCol; j++) {
        const headerName = Object.values(rows[0]).find((r) => r.id === `0_${j}`).value;
        clipboard += j === startCol ? `${rows[i][headerName]?.value}` : `\t${rows[i][headerName]?.value}`;
      }
      if (i !== endRow) clipboard += `\r\n`;
    }
    await navigator.clipboard.writeText(clipboard);
    setPopUp({ open: true, message: 'Copied!' });
  };

  const pasteCells = ({ startCol, startRow }) => {
    (async () => {
      const text = await navigator.clipboard.readText();
      if (text) {
        const cols = text.split('\r\n');
        const clipboard = cols.map((item) => item.split('\t'));
        clipboard.forEach((row, i) => {
          const start = i + startRow;
          row.forEach((val, j) => {
            const key = j + startCol;
            if (Object.keys(rows?.[start])?.[key]) {
              rows[start][Object.keys(rows[start])[key]].value = val;
            }
          });
        });
        setSelectedCells({
          startRow: null,
          endRow: null,
          startCol: null,
          endCol: null,
        });
      }
    })();
  };

  const deleteRangeCells = ({ startCol, startRow, endCol, endRow }) => {
    for (let i = startRow; i <= endRow; i++) {
      for (let j = startCol; j <= endCol; j++) {
        rows[i][Object.keys(rows[i])[j]].value = '';
      }
    }
    setSelectedCells({
      startRow: null,
      endRow: null,
      startCol: null,
      endCol: null,
    });
  };

  const handleKeyPress = useCallback(
    (e) => {
      const { startCol, startRow, endCol, endRow } = selectedCells;
      const isSelected = Number.isInteger(startCol) && Number.isInteger(startRow) && Number.isInteger(endCol) && Number.isInteger(endRow);
      if (isSelected) {
        // if selected cells required
        if (e.ctrlKey && e.which === 67) {
          // copy cells range
          copyRangeCells(selectedCells);
        }
        if (e.which === 46 || e.which === 8) {
          // delete selected range
          deleteRangeCells(selectedCells);
        }
      }
      if (e.ctrlKey && e.which === 86) {
        // paste copied cells range
        pasteCells(selectedCells);
      }
    },
    [selectedCells]
  );

  const handleMouseMove = (e) => {
    if (scrollingInterval.current) clearInterval(scrollingInterval.current);
    if (!isDown.current) return;
    const bounds = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - bounds.left;
    const y = e.clientY - bounds.top;
    const scrollHorizontalIndex = y < 30 ? -1 : e.currentTarget.clientHeight - y < 100 ? 1 : 0;
    const scrollVerticalIndex = x < 30 ? -1 : e.currentTarget.clientWidth - x < 100 ? 1 : 0;
    if (scrollHorizontalIndex || scrollVerticalIndex) {
      scrollingInterval.current = setInterval(() => {
        containerRef.current.scrollBy({
          top: 100 * scrollHorizontalIndex,
          left: 100 * scrollVerticalIndex,
          behavior: 'smooth',
        });
      }, 50);
    }
  };

  const handleImport = (e) => {
    dispatch(startLoading());
    const reader = new FileReader();
    reader.onload = (e) => {
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      // TODO handle all worksheets
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const head = XLSX.utils.sheet_to_json(ws, { header: 1 })[0];

      const newHead = {};
      head.forEach((h) => {
        newHead[h] = h;
      });

      const data = wb.SheetNames.map((sheet) => XLSX.utils.sheet_to_row_object_array(wb.Sheets[sheet]));
      data[0].unshift(newHead);
      const maxLen = Math.max(...data.map((item) => item.length));

      // rowsRef.current = createRows(data.length + 10, maxLen + 10, 0, true, data, actionDefs);
      setRows(createRows(data.length + 10, maxLen + 10, 0, ...data, true, actionDefs));
      dispatch(stopLoading());
    };
    reader.readAsArrayBuffer(e.target.files[0]);
  };

  const handleExport = () => {
    dispatch(startLoading());
    const aoa = rows.map((item) => {
      return Object.values(item).map((value) => value.value);
    });
    const ws = XLSX.utils.aoa_to_sheet(aoa);

    // add to workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, `sheet_${Date.now()}`);

    // generate an XLSX file
    XLSX.writeFile(wb, `sheet_${new Date().toLocaleDateString()}.xlsx`);
    dispatch(stopLoading());
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [selectedCells]);

  const wrapperStyle = {
    height: rows.length * rowHeight,
    paddingTop: (rowHeight * offsetBuffer * startIndex) / offsetBuffer,
    width: Object.keys(rows[0] || {}).length * columnWidth,
    paddingLeft: (columnWidth * offsetBuffer * startIndexCol) / offsetBuffer,
  };

  // useEffect(() => {
  // virtualizedGridRef.current = rows.slice(showing.startIndex, showing.endIndex)
  // }, [showing])

  // useLayoutEffect(() => {
  //   let virtualRows = rows.slice(showing.startIndex, showing.endIndex)
  //   virtualRows = virtualRows.map(item => item.slice(showing.startIndexCol, showing.endIndexCol))
  //   setVirtualizedGrid(virtualRows)
  //   console.log('showing', showing)
  // }, [showing, rows])

  const timeoutId = useRef(null);

  const handleContScroll = () => {
    const containerScrollTop = containerRef.current.scrollTop;
    let index = (containerScrollTop * offsetBuffer) / (rowHeight * offsetBuffer);
    const containerScrollLeft = containerRef.current.scrollLeft;
    let indexCol = (containerScrollLeft * offsetBuffer) / (columnWidth * offsetBuffer);
    if (index < offsetBuffer) index = offsetBuffer;
    if (indexCol < offsetBuffer) indexCol = offsetBuffer;
    // console.log('containerScrollLeft', containerScrollLeft)
    // console.log('indexCol', indexCol, {
    //   startIndexCol: Math.round(indexCol - offsetBuffer),
    //   endIndexCol: Math.round(indexCol + offsetBuffer + visibleCols),
    // })

    const calculatedValues = {
      startIndex: Math.round(index - offsetBuffer),
      endIndex: Math.round(index + offsetBuffer + visibleRows),
      startIndexCol: Math.round(indexCol - offsetBuffer),
      endIndexCol: Math.round(indexCol + offsetBuffer + visibleCols),
    };

    if (startIndex !== calculatedValues.startIndex || endIndex !== calculatedValues.endIndex || startIndexCol !== calculatedValues.startIndexCol || endIndexCol !== calculatedValues.endIndexCol) {
      timeoutId.current && clearTimeout(timeoutId.current);
      timeoutId.current = setTimeout(() => {
        setShowing(calculatedValues);
      }, 1);
    }
  };

  useEffect(() => setRows(createRows(50, 50, 0, data, true, actionDefs)), [data]);

  return (
    <>
      <Snackbar
        open={popUp.open}
        autoHideDuration={2000}
        onClose={() => {
          setPopUp({ open: false });
        }}
        message={popUp.message}
      />
      <div className={styles.sheetActions}>
        {!isScore && (
          <>
            <input accept=".xlsx" style={{ display: 'none' }} id="raised-button-file" onChange={(e) => handleImport(e)} type="file" />
            <label style={{ marginRight: '20px' }} htmlFor="raised-button-file">
              <Button variant="outlined" color="primary" component="span">
                Import
              </Button>
            </label>
          </>
        )}
        <Button variant="outlined" color="primary" onClick={handleExport}>
          export
        </Button>
      </div>
      <div
        className={styles.sheetWrapper}
        onScroll={handleContScroll}
        ref={containerRef}
        onMouseMove={(e) => {
          handleMouseMove(e);
        }}
      >
        <div
          ref={wrapperRef}
          style={{
            height: wrapperStyle.height,
            paddingTop: wrapperStyle.paddingTop,
            paddingLeft: wrapperStyle.paddingLeft,
            width: wrapperStyle.width,
          }}
        >
          <div aria-hidden="true" tabIndex="0" ref={contRef} className={styles.sheetContainer} onMouseDown={handleDown} onMouseUp={handleUp}>
            {rows.slice(startIndex, endIndex).map((row, i) => (
              <div key={row[0]?.id} className={styles.row} style={{ borderBottom: i === rows.slice(startIndex, endIndex).length - 1 ? '1px solid rgb(114, 114, 114)' : 'unset' }}>
                {!!Object.entries(row).length && (
                  <>
                    {i !== 0 && handleSaveLine ? (
                      <div style={{ height: rowHeight, backgroundColor: 'transparent', width: '100px' }}>
                        <div className={styles.cell}>
                          <Button variant="contained" color="primary" size="large" onClick={() => handleSaveLine(row)}>
                            Save
                          </Button>
                        </div>
                      </div>
                    ) : (
                      handleSaveLine && (
                        <div style={{ height: rowHeight, backgroundColor: 'transparent', width: '100px' }}>
                          <div className={styles.cell} />
                        </div>
                      )
                    )}
                    {Object.entries(row)
                      .slice(startIndexCol, endIndexCol)
                      .map(
                        ([key, cell], j) =>
                          key !== 'key' && (
                            <MemorizedCell
                              duplicateTable={duplicateTable}
                              key={`${key}_${cell.id}`}
                              rowHeight={rowHeight}
                              customCellStyles={customCellStyles}
                              handleMouseEnterCell={handleMouseEnterCell}
                              cell={cell}
                              handleCellValueChange={handleCellValueChange}
                              j={key}
                              handleOnBlur={(e, cell, j) => handleOnBlur(e, cell, j, row)}
                              i={i + startIndex}
                              isDown={isDown}
                              isScore={isScore}
                              isSelected={isInRange(selectedCells.startRow, selectedCells.endRow, i + startIndex) && isInRange(selectedCells.startCol, selectedCells.endCol, j)}
                              setSelectedCells={setSelectedCells}
                            />
                          )
                      )}
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      {!isScore && (
        <Button
          onClick={() => {
            handleAddRow(1);
          }}
          variant="contained"
          color="primary"
        >
          Add Row
        </Button>
      )}
    </>
  );
}

Sheet.defaultProps = {
  rowHeight: 40,
  offsetBuffer: 15,
  data: [],
};

Sheet.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.arrayOf(PropTypes.object),
  rowHeight: PropTypes.number,
  offsetBuffer: PropTypes.number,
};

export default memo(Sheet);

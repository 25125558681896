import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Sheet from '../../../components/sheet/sheet';
import { incomeLevel, levelOfDevelopment, regions } from './constants';
import styles from './view1.module.scss';
import { setView1Table } from '../../../redux/actions/cms/view1';
import { getCountryEntries } from '../../../utils';
import { useLocation } from 'react-router-dom';

export default function View1() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isTracker = pathname.includes('tracker');
  const data = useSelector((store) => store?.cmsView1?.table);
  const cellTypes = {
    2: { type: 'select', options: regions },
    3: { type: 'select', options: levelOfDevelopment },
    4: { type: 'select', options: incomeLevel },
    5: { type: 'select', options: regions },
    6: { type: 'select', options: levelOfDevelopment },
    7: { type: 'select', options: incomeLevel },
  };

  useEffect(() => initialRequest(), []);

  const initialRequest = () => {
    const setInitialData = async () => {
      const responseData = await getCountryEntries(isTracker ? 'tracker' : 'benchmark');
      const newData = await refactorData(responseData);
      dispatch(setView1Table(newData));
    };
    setInitialData();
  };

  const refactorData = (data) => {
    const header = {};
    data?.length &&
      Object.keys(data[0]).forEach((elem) => {
        header[elem] = elem;
      });
    return [header, ...data];
  };

  return (
    <div style={{ padding: '20px' }}>
      <h1>{isTracker ? 'Tracker affiliations' : 'Benchmark affiliations'}</h1>

      <div className={styles.sheetCont}>
        <Sheet data={data} cellTypes={cellTypes} />
      </div>
    </div>
  );
}

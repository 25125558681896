import { Box, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { getRowAndColFromId, isObject } from '../../../components/sheet/helpers';
import Sheet from '../../../components/sheet/sheet';
import { setSubView2Table } from '../../../redux/actions/cms/subView2';
import { getCountryNameAndFlags, getView2DataByCountry, getYears, sendScore } from '../../../utils';
import { incomeLevel, levelOfDevelopment, regions } from './constants';
import styles from './view2.module.scss';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

export default function SubViewCountry() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { country: countryName } = useParams();
  const [inputValue, setInputValue] = useState(countryName);
  const [data, setData] = useState([]);
  const [years, setYears] = useState([]);
  const [country, setCountry] = useState(countryName);
  const [countries, setCountries] = useState([]);
  const cellTypes = {
    2: { type: 'select', options: regions },
    3: { type: 'select', options: levelOfDevelopment },
    4: { type: 'select', options: incomeLevel },
  };

  useEffect(() => {
    setInputValue(countryName);
    setCountry(countryName);
    history.push(countryName, countryName);
  }, []);

  const actionDefs = {
    handleClick: (cell) => {
      const [rowIndex] = getRowAndColFromId(cell.id);
      const year = data[rowIndex][0];
      history.push({ pathname: `/cms/view-2/`, state: { year } });
    },
  };

  const handleCountryChange = (_, v) => {
    if (v) {
      setCountry(v.country);
      setInputValue(v.country);
      history.push(v.country, v.country);
    }
  };

  useEffect(() => {
    const getYearData = async () => {
      const yearsData = await getYears('tracker');
      setYears(yearsData);
    };
    getYearData();
  }, []);

  useEffect(() => {
    const getCountryList = async () => {
      const countries = await getCountryNameAndFlags();
      setCountries(countries);
    };
    getCountryList();
  }, []);

  useEffect(() => {
    const setInitialData = async () => {
      const responseData = await getInitialData();
      dispatch(setSubView2Table(data));
      setData(responseData);
    };
    setInitialData();
  }, [country, years]);

  const getInitialData = async (yearsData = years) => {
    const data = await Promise.all(
      yearsData.map(async (year) => {
        const { data } = await getView2DataByCountry(`tracker/${year}/${countryName}`);
        return { ...data };
      })
    );
    const header = {};
    data?.length &&
      Object.keys(data[0]).forEach((elem) => {
        header[elem] = elem;
      });

    return data.length ? [header, ...data] : [];
  };

  const handleDataChange = (data) => setData(data);

  const handleOnBlur = async (e, cell, j, row) => {
    const newCell = isObject(cell)
      ? {
          ...cell,
          value: {
            ...cell.value,
            score: e.target.value,
            overridden: true,
          },
        }
      : { ...cell, value: e.target.value };
    const requestRow = {
      ...row,
      [j]: newCell,
    };
    Object.keys(requestRow).forEach((key) => {
      requestRow[key] = requestRow[key].value;
    });
    const newData = _.cloneDeep(data);
    const index = newData.findIndex((item) => item.key === requestRow.key);
    const responseData = await sendScore(`tracker`, requestRow);
    newData[index] = responseData;
    setData(responseData);
  };

  return (
    <div style={{ padding: '20px' }}>
      {!!countries?.length && (
        <div className={styles.filters}>
          <Autocomplete
            disablePortal
            options={countries}
            getOptionLabel={(option) => option.country || ''}
            style={{ width: '300px' }}
            inputValue={inputValue || ''}
            onInputChange={(e) => e && setInputValue(e.target.value)}
            value={country}
            getOptionSelected={(option) => option.country}
            onChange={handleCountryChange}
            renderInput={(params) => <TextField variant="outlined" {...params} style={{ width: '100%' }} label="Country" />}
            renderOption={(props) => (
              <Box component="span" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img loading="lazy" width="20" src={props.flagURL} srcSet={props.flagURL} alt="" />
                {props.country}
              </Box>
            )}
          />
        </div>
      )}
      <div className={styles.sheetCont}>
        <Sheet actionDefs={actionDefs} data={data} cellTypes={cellTypes} isScore setter={handleDataChange} handleOnBlur={handleOnBlur} />
      </div>
    </div>
  );
}

import { SAVE_SUB_VIEW3_TABLE, SET_SUB_VIEW3_TABLE } from '../../types';

const setSubView3Table = (data) => async (dispatch) => {
  dispatch({
    type: SET_SUB_VIEW3_TABLE,
    payload: { loading: true },
  });
  const payload = {
    table: data,
    loading: false,
  };
  dispatch({
    type: SET_SUB_VIEW3_TABLE,
    payload,
  });
};

const saveSubView3Table = () => async (dispatch) => {
  dispatch({
    type: SAVE_SUB_VIEW3_TABLE,
    payload: { loading: true },
  });
  dispatch({
    type: SAVE_SUB_VIEW3_TABLE,
  });
};

export { setSubView3Table, saveSubView3Table };

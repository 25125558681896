import { Button, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import Sheet from '../../../components/sheet/sheet';
import { getNewYear, getView2Data, sendScore, updateViews } from '../../../utils';
import styles from './view2.module.scss';
import { cache, isObject } from '../../../components/sheet/helpers';
import { DUPLICATE_RAW_DATA_VIEW2 } from '../../../redux/types';
import { setRawData } from '../../../redux/actions/cms/rawDataView2';
import _ from 'lodash';

export default function RawData() {
  const dispatch = useDispatch();
  const loading = useSelector((store) => store?.cmsRawDataView2?.loading);
  const [data, setData] = useState(cache.getItem(DUPLICATE_RAW_DATA_VIEW2));
  const [year, setYear] = useState(2021);
  const duplicateTable = useSelector((store) => store?.cmsRawDataView2.table);
  // const [cellTypes, setCellTypes] = useState({
  //   2: { type: 'select', options: regions },
  //   3: { type: 'select', options: levelOfDevelopment },
  //   4: { type: 'select', options: incomeLevel },
  // });

  // const actionDefs = {
  //   handleClick: (cell) => {
  //     const [rowIndex] = getRowAndColFromId(cell.id);
  //     const year = data[rowIndex][0];
  //     history.push({ pathname: `/cms/view-2/`, state: { year } });
  //   },
  // };

  // const handleCountryChange = (_, v) => {
  //   v?.country && setCountry(v?.country);
  // };

  // useEffect(() => {
  //   (async () => {
  //     const countries = await getCountryNameAndFlags();
  //     setCountries(countries);
  //   })();
  //   dispatch(setRawData());
  // }, []);

  const handleSave = async () => {
    data.shift();
    data.length &&
      (await Promise.all(
        data?.forEach(async (elem) => {
          await updateViews(`tracker/${elem.key}`, elem);
        })
      ).then(() => {
        cache.removeItem(DUPLICATE_RAW_DATA_VIEW2);
        initialRequest();
      }));
  };

  useEffect(() => initialRequest(), []);

  const initialRequest = () => {
    const setInitialData = async () => {
      const responseData = await getInitialData(year);

      const storedData = cache.getItem(DUPLICATE_RAW_DATA_VIEW2);

      if (!storedData.length || storedData[1].year !== year) {
        setData(responseData);
        cache.setItem(DUPLICATE_RAW_DATA_VIEW2, responseData);
      }

      dispatch(setRawData(responseData));
    };
    setInitialData();
  };

  const getInitialData = async (searchYear) => {
    const { data } = await getView2Data(`tracker/${searchYear}`);
    const header = {};
    data?.length &&
      Object.keys(data[0]).forEach((elem) => {
        header[elem] = elem;
      });
    return [header, ...data];
  };

  const handleDataChange = (data) => {
    setData(data);
    cache.setItem(DUPLICATE_RAW_DATA_VIEW2, data);
  };

  const createNewYear = async () => {
    await getNewYear(`tracker/${year}`);
    initialRequest();
  };

  const getData = async () => {
    initialRequest();
  };

  const handleSaveLine = async (row) => {
    Object.keys(row).forEach((key) => {
      row[key] = row[key].value;
    });

    const data = await updateViews(`tracker/${row.key}`, row);
    if (data) {
      cache.removeItem(DUPLICATE_RAW_DATA_VIEW2);
      initialRequest();
    }
  };

  const handleOnBlur = async (e, cell, j, row) => {
    const newCell = isObject(cell)
      ? {
          ...cell,
          value: {
            ...cell.value,
            response: e.target.value,
          },
        }
      : { ...cell, value: e.target.value };
    const requestRow = {
      ...row,
      [j]: newCell,
    };
    Object.keys(requestRow).forEach((key) => {
      requestRow[key] = requestRow[key].value;
    });
    const newData = _.cloneDeep(data);
    const index = newData.findIndex((item) => item.key === requestRow.key);
    const responseData = await sendScore(`tracker`, requestRow);
    newData[index] = responseData;
    setData(responseData);
  };

  return (
    <div style={{ padding: '20px' }}>
      <div className={styles.filters}>
        <h1>Raw Data</h1>
      </div>
      <div className={styles.sheetCont}>
        <Sheet handleSaveLine={handleSaveLine} data={data} rowHeight={55} duplicateTable={duplicateTable} setter={handleDataChange} handleOnBlur={handleOnBlur} isScore={false} />
      </div>
      <TextField
        type={'number'}
        id="outlined-basic"
        variant="outlined"
        onChange={(e) => setYear(e.target.value)}
        className={styles.input}
        value={year}
        InputProps={{
          className: styles.inputNumber,
          classes: {
            root: styles.inputProps,
          },
        }}
      />
      <Button variant="contained" color="primary" size="large" className={styles.getData} onClick={getData} disabled={loading}>
        Get {year}
      </Button>
      <Button variant="contained" color="primary" size="large" className={styles.create} onClick={createNewYear} disabled={loading}>
        Create {year}
      </Button>
      <Button variant="contained" color="primary" size="large" className={styles.save} onClick={handleSave} disabled={loading}>
        {loading ? (
          <>
            Loading &nbsp; <ClipLoader size={20} />{' '}
          </>
        ) : (
          'Save all'
        )}
      </Button>
    </div>
  );
}

import { Button } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styles from './home.module.scss';

export default function Home() {
  const history = useHistory();

  const structure = [
    {
      label: 'Tracker',
      children: [
        { label: 'Publish', path: 'cms/view-2/publish' },
        { label: 'Raw Data', path: 'cms/view-2/raw-data' },
        { label: 'Country list', path: 'cms/view-2' },
        { label: 'Individual Country', path: 'cms/view-2/Afghanistan' },
        { label: 'Country affiliations', path: 'cms/tracker/view-1' },
      ],
    },
    {
      label: 'Benchmark',
      children: [
        { label: 'Publish', path: 'cms/view-3/publish' },
        { label: 'Raw Data', path: 'cms/view-3/raw-data' },
        { label: 'Country list', path: 'cms/view-3' },
        { label: 'Individual Country', path: 'cms/view-3/Afghanistan' },
        { label: 'Country affiliations', path: 'cms/benchmark/view-1' },
      ],
    },
    // {
    //   label: 'Country affiliations',
    //   children: [{ label: 'List', path: 'cms/view-1' }],
    // },
  ];

  // const handleLogin = () => {
  // (async () => {

  //     let credentials: NtlmCredentials = {
  //         username: 'vardanya',
  //         password: 'Welcome@!',
  //     }

  //     let client = NtlmClient(credentials)

  //     try {
  //         let resp = await client({
  //             url: 'https://www.itu.int/net4/itu-d/metrics-protected/api/v1.1/cms/tracker/history/2021/France',
  //             method: 'GET'
  //         });
  //         console.log(resp.data);
  //     }
  //     catch (err) {
  //         console.log(err)
  //         console.log("Failed")
  //     }

  // })()
  // localStorage.setItem('isAuth', true);
  // dispatch(logIn({ userName: login, password }));
  // setIsLogged(true);
  // };

  // useEffect(() => {
  //     setIsLogged(localStorage.getItem('isAuth'))
  // }, [])

  // if (isLogged) {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.cont}>
          {structure.map((item) => (
            <div className={styles.tbl} key={item.label}>
              {item?.label && <h4 className={styles.header}>{item.label}</h4>}
              {item?.children?.length &&
                item?.children.map((child) => (
                  <Button key={child.label} variant="contained" className={styles.btn} color="primary" onClick={() => child?.path && history.push(child.path)}>
                    {child.label}
                  </Button>
                ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
  // }

  // useEffect(() => {
  //     const authOpts = {
  //         username: 'vardanya',
  //         password: 'Welcome@!',
  //         domain: '',
  //         workstation: '',
  //     };
  //     var basicHeaders = new Headers();
  //     var requestOptions = {
  //         method: 'GET',
  //         headers: basicHeaders,
  //     };
  //     const url = 'https://www.itu.int/net4/itu-d/metrics-protected/api/v1.1/cms/users'

  //     const headers = new Headers({
  //         Authorization: ntlm.createType1Message(authOpts),
  //         Connection: 'keep-alive',
  //     });

  //     fetch(url, requestOptions)
  //         .then((response) => {
  //             console.log(response.headers.get('WWW-Authenticate'));
  //             fetch(url, {
  //                 headers: headers,
  //             }).then(res => {
  //                 console.log('res', res.headers);
  //             }).catch(err => {
  //                 fetch(url, requestOptions)
  //             })

  //             return response.text();
  //         })
  //         .then((result) => {
  //             console.log('result', result.headers);
  //         })
  //         .catch((error) => {
  //             console.log(error);
  //             // console.log('error', error);
  //         });
  // }, []);

  // return (
  //     <div className={styles.container}>
  //         <div className={styles.content}>
  //             <TextField
  //                 variant="outlined"
  //                 margin="normal"
  //                 value={login}
  //                 onChange={(e) => {
  //                     setLogin(e.target.value);
  //                 }}
  //                 label="Login"
  //             />
  //             <TextField
  //                 variant="outlined"
  //                 type="password"
  //                 margin="normal"
  //                 value={password}
  //                 onChange={(e) => {
  //                     setPassword(e.target.value);
  //                 }}
  //                 label="Password"
  //             />
  //             <Button variant="contained" fullWidth color="primary" onClick={handleLogin}>
  //                 Login
  //             </Button>
  //         </div>
  //     </div>
  // );
}

import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { IconButton, Tooltip } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import styles from './sheet.module.scss';
import { getRowAndColFromId, isObject } from './helpers';

function CellRenderer({ cell, i, j, handleCellValueChange, isSelected, setSelectedCells, handleOnBlur, handleMouseEnterCell, customCellStyles, rowHeight, duplicateTable, isScore }) {
  const [renderInput, setRenderInput] = useState(null);
  const [value, setValue] = useState(isObject(cell) ? (!isScore ? cell.value.response || '' : cell.value.score || '') : cell.value);
  const cellRef = useRef();
  const [color, setColor] = useState('transparent');

  const handleMouseDown = useCallback((e, cellItem) => {
    const [rowIndex, columnIndex] = getRowAndColFromId(cellItem.id);
    setSelectedCells({
      startRow: rowIndex,
      endRow: null,
      startCol: columnIndex,
      endCol: null,
    });
  }, []);

  const handleChange = (e) => setValue(e.target.value);

  const handleInputChange = (e, cell, i, j) => {
    handleChange(e);
    handleCellValueChange(e, cell, i, j);
  };

  useEffect(() => {
    if (!isScore) {
      if (duplicateTable.length) {
        if (isObject(cell)) {
          setColor(cell.value.response !== duplicateTable?.[i]?.[j].response ? '#c0d4f1' : 'transparent');
        } else {
          setColor(cell.value !== duplicateTable?.[i]?.[j] ? '#c0d4f1' : 'transparent');
        }
      } else {
        setColor('transparent');
      }
    } else {
      setColor('transparent');
    }
  }, [duplicateTable]);

  const inputRenderer = () => {
    switch (cell?.cellType?.type) {
      case 'select':
        return (
          <select
            value={value}
            className={styles.cellInput}
            onChange={(e) => {
              setColor(
                !isScore
                  ? isObject(cell)
                    ? +e.target.value !== duplicateTable[i][j].response
                      ? '#c0d4f1'
                      : 'transparent'
                    : +e.target.value !== duplicateTable[i][j]
                    ? '#c0d4f1'
                    : 'transparent'
                  : 'transparent'
              );
              handleCellValueChange(e, cell, i, j);
            }}
            onBlur={(e) => handleOnBlur(e, cell, j)}
          >
            {cell.cellType.options.map((item) => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </select>
        );
      case 'input':
        return (
          <input
            // eslint-disable-next-line
            autoFocus
            // onBlur={(e)=>handleCellValueChange(e, cell, i, j)}
            value={value}
            className={styles.cellInput}
            onChange={(e) => {
              setColor(
                !isScore
                  ? isObject(cell)
                    ? +e.target.value !== duplicateTable[i][j].response
                      ? '#c0d4f1'
                      : 'transparent'
                    : +e.target.value !== duplicateTable[i][j]
                    ? '#c0d4f1'
                    : 'transparent'
                  : 'transparent'
              );
              handleInputChange(e, cell, i, j);
            }}
            onBlur={(e) => handleOnBlur(e, cell, j)}
          />
        );
      case 'action':
        return (
          <div>
            <IconButton
              size="small"
              onClick={() => {
                cell.actionDefs.handleClick(cell);
              }}
            >
              <Edit />
            </IconButton>
          </div>
        );
      default:
        return (
          <input
            // eslint-disable-next-line
            autoFocus
            value={value}
            className={styles.cellInput}
            onChange={(e) => {
              setColor(
                !isScore
                  ? isObject(cell)
                    ? +e.target.value !== duplicateTable[i][j].response
                      ? '#c0d4f1'
                      : 'transparent'
                    : +e.target.value !== duplicateTable[i][j]
                    ? '#c0d4f1'
                    : 'transparent'
                  : 'transparent'
              );
              handleInputChange(e, cell, i, j);
            }}
            onBlur={(e) => handleOnBlur(e, cell, j)}
          />
        );
    }
  };

  const handleCellClick = useCallback((e, cellItem) => {
    const [rowIndex, columnIndex] = cellItem.id.split('_').map((item) => +item);
    setSelectedCells({
      startRow: rowIndex,
      endRow: rowIndex,
      startCol: columnIndex,
      endCol: columnIndex,
    });
  }, []);

  const handleCellDblClick = (e, item) => setRenderInput(item.id);

  const handleKeyDown = useCallback((e, item) => (e.which === 13 ? setRenderInput(null) : setRenderInput(item.id)), []);

  const isDown = useRef(false);

  const handleColMouseDown = () => {
    isDown.current = true;
    window.addEventListener('mouseup', handleColMouseUp);
    window.addEventListener('mousemove', handleColResize);
  };
  const handleColMouseUp = () => {
    isDown.current = false;
    window.removeEventListener('mouseup', handleColMouseUp);
    window.removeEventListener('mousemove', handleColResize);
  };

  const handleColResize = (e) => {
    if (isDown.current) {
      // TODO fix resizeing logic
      const rect = cellRef.current.getBoundingClientRect();
      customCellStyles.current = {
        ...customCellStyles.current,
        [j]: { ...customCellStyles.current?.[j], width: `${e.screenX - rect.x}px` },
      };
    }
  };

  return (
    <div style={{ height: rowHeight, backgroundColor: color }}>
      <div
        style={customCellStyles.current[j]}
        aria-hidden="true"
        onMouseEnter={(e) => handleMouseEnterCell(e, cell)}
        onMouseDown={(e) => handleMouseDown(e, cell)}
        onClick={(e) => handleCellClick(e, cell)}
        onDoubleClick={(e) => handleCellDblClick(e, cell)}
        onKeyPress={(e) => handleKeyDown(e, cell)}
        // onMouseLeave={handleMouseLeaveCell}
        key={cell.id}
        // eslint-disable-next-line
        tabIndex="0"
        ref={cellRef}
        className={clsx(
          {
            [styles.columnHeader]: cell.type === 'column',
            // [styles.rowsFirstCell]: cell.type === "row",
            [styles.selectedCell]: isSelected,
          },
          styles.cell
        )}
      >
        <span onMouseDown={handleColMouseDown} role="none" className={styles.controll} />
        {renderInput === cell.id ? (
          <ClickAwayListener
            onClickAway={() => {
              setRenderInput(null);
            }}
          >
            <div>{inputRenderer()}</div>
          </ClickAwayListener>
        ) : cell.cellType.type === 'action' ? (
          inputRenderer()
        ) : (
          <Tooltip key={cell.id} title={value} arrow enterDelay={1000} enterNextDelay={1000} placement="top">
            <span>{value}</span>
          </Tooltip>
        )}
      </div>
    </div>
  );
}

export default memo(CellRenderer);

import axios from 'axios';
import store from '../redux/store';
import { setErrorModal } from '../redux/actions/ErrorActions';
import { startLoading, stopLoading } from '../redux/actions/LoadingActions';

const baseURL = 'https://www.itu.int/net4/itu-d/metrics/api/v1.1';
//  https://www.itu.int/net4/itu-d/metrics/api/v1.1/benchmark/entries?brief=false
const publicApi = axios.create({ baseURL });

publicApi.interceptors.request.use((request) => {
  store.dispatch(startLoading());
  return request;
});

publicApi.interceptors.response.use(
  (response) => {
    store.dispatch(stopLoading());
    return response;
  },
  (error) => {
    store.dispatch(setErrorModal({ show: true }));
    store.dispatch(stopLoading());
    return Promise.reject(error);
  }
);

const baseURLCMS = 'https://www.itu.int/net4/itu-d/metrics-protected/api/v1.1/cms';
const cmsProtectedApi = axios.create({ baseURL: baseURLCMS });

cmsProtectedApi.interceptors.request.use((request) => {
  store.dispatch(startLoading());
  return request;
});

cmsProtectedApi.interceptors.response.use(
  (response) => {
    store.dispatch(stopLoading());
    return response;
  },
  (error) => {
    store.dispatch(setErrorModal({ show: true }));
    store.dispatch(stopLoading());
    return Promise.reject(error);
  }
);

export { publicApi, cmsProtectedApi, baseURLCMS };

import { SAVE_VIEW2_TABLE, SET_VIEW2_TABLE } from '../../types';

let timeoutId = null;

const setView2Table = (data) => async (dispatch) => {
  dispatch({
    type: SET_VIEW2_TABLE,
    payload: { loading: true },
  });
  const payload = {
    table: data,
    loading: false,
  };
  dispatch({
    type: SET_VIEW2_TABLE,
    payload,
  });
};

const saveView2Table = () => async (dispatch) => {
  dispatch({
    type: SAVE_VIEW2_TABLE,
    payload: { loading: true },
  });
  if (timeoutId) clearTimeout(timeoutId);
  timeoutId = setTimeout(() => {
    dispatch({
      type: SAVE_VIEW2_TABLE,
    });
  }, 1000);
};

export { setView2Table, saveView2Table };

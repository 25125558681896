export const theme = {
  breakpoints: {
    keys: ['xs', 'xsm', 'sm', 'md', 'lg', 'xl', 'xxl'],
    values: {
      xs: 0,
      xsm: 480,
      sm: 600,
      md: 1150,
      lg: 1366,
      xl: 1460,
      xxl: 1600,
    },
  },
  palette: {
    primary: {
      main: '#009CD6',
    },
  },
};

export const agGridDefaults = {
  enableCellTextSelection: true,
  rowBuffer: 10,
  tooltipShowDelay: 10,
};

import React from 'react';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { Translation } from 'react-i18next';
import styles from './errorBoundary.module.scss';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }
  // TODO uncomment if errors must be logged remote
  // componentDidCatch(error, errorInfo) {
  //   logErrorToMyService(error, errorInfo);
  // }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    return hasError ? (
      <Translation>
        {(t) => (
          <div className={styles.background}>
            <h1>{t('modal.error.title')}</h1>
            <ErrorOutlineIcon color="secondary" />
          </div>
        )}
      </Translation>
    ) : (
      children
    );
  }
}

import { SAVE_RAWDATA_VIEW3_TABLE, SET_RAWDATA_VIEW3_TABLE } from '../../types';

let timeoutId = null;

const setRawData = (newData) => async (dispatch) => {
  dispatch({
    type: SET_RAWDATA_VIEW3_TABLE,
    payload: { loading: true },
  });
  const payload = {
    table: newData,
    loading: false,
  };
  dispatch({
    type: SET_RAWDATA_VIEW3_TABLE,
    payload,
  });
};

const saveRawData = () => async (dispatch) => {
  dispatch({
    type: SAVE_RAWDATA_VIEW3_TABLE,
    payload: { loading: true },
  });
  if (timeoutId) clearTimeout(timeoutId);
  timeoutId = setTimeout(() => {
    dispatch({
      type: SAVE_RAWDATA_VIEW3_TABLE,
    });
  }, 1000);
};

export { setRawData, saveRawData };

/* eslint-disable react/jsx-filename-extension */
import React, { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter, Redirect, Route, Switch, useHistory } from 'react-router-dom';
import ReactGA from 'react-ga';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { CircularProgress, createTheme, MuiThemeProvider } from '@material-ui/core';
import store from './redux/store';
import i18n from './i18n';
import './index.scss';
import ErrorBoundary from './components/errorBoundary';
import { theme } from './utils/constants';
import NotFound from './pages/tracker/NotFound';
import CMS from './Apps/CMS';
import { createRoot } from 'react-dom/client';

const Tracker = lazy(() => import('./Apps/tracker'));
const Lab = lazy(() => import('./Apps/lab'));
const Benchmark = lazy(() => import('./Apps/benchmark'));
const UnifiedFramework = lazy(() => import('./Apps/unified-framework'));

const themeCustom = createTheme(theme);

function App() {
  const history = useHistory();
  useEffect(() => {
    const googleSiteTag = process.env.REACT_APP_GOOGLE_SITE_TAG || null;

    if (googleSiteTag) {
      ReactGA.initialize(googleSiteTag);
      // ReactGA.set({ userId: auth.currentUserId() }); // TODO use after authentication is set

      history.listen((location) => {
        ReactGA.set({ page: location.pathname }); // Update the user's current page
        ReactGA.pageview(location.pathname); // Record a page view for the given page
      });
    }
  }, [history]);

  return (
    <Switch>
      <Route exact path="/">
        <Suspense fallback={<CircularProgress className="fallback" />}>
          <Redirect to="/tracker/" />
        </Suspense>
      </Route>
      <Route path="/tracker">
        <Suspense fallback={<CircularProgress className="fallback" />}>
          <Tracker />
        </Suspense>
      </Route>
      <Route path="/lab/">
        <Suspense fallback={<CircularProgress className="fallback" />}>
          <Lab />
        </Suspense>
      </Route>
      <Route path="/benchmark/">
        <Suspense fallback={<CircularProgress className="fallback" />}>
          <Benchmark />
        </Suspense>
      </Route>
      <Route path="/cms/">
        <Suspense fallback={<CircularProgress className="fallback" />}>
          <CMS />
        </Suspense>
      </Route>
      <Route path="/unified-framework/">
        <Suspense fallback={<CircularProgress className="fallback" />}>
          <UnifiedFramework />
        </Suspense>
      </Route>
      <Route exact path="/not-found">
        <Suspense fallback={<CircularProgress className="fallback" />}>
          <NotFound />
        </Suspense>
      </Route>
      <Route to="*">
        <Suspense fallback={<CircularProgress className="fallback" />}>
          <NotFound withLink />
        </Suspense>
      </Route>
    </Switch>
  );
}
const container = document.getElementById('root');
const root = createRoot(container);

// if (rootElement.hasChildNodes()) {
//   hydrate(
//     <ErrorBoundary>
//       <I18nextProvider i18n={i18n}>
//         <Provider store={store}>
//           <BrowserRouter>
//             <MuiThemeProvider theme={themeCustom}>
//               <App />
//             </MuiThemeProvider>
//           </BrowserRouter>
//         </Provider>
//       </I18nextProvider>
//     </ErrorBoundary>,
//     rootElement
//   );
// } else {
root.render(
  <ErrorBoundary>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <BrowserRouter>
          <MuiThemeProvider theme={themeCustom}>
            <App />
          </MuiThemeProvider>
        </BrowserRouter>
      </Provider>
    </I18nextProvider>
  </ErrorBoundary>
);
// }
